:root {
    --flowui-component-library-gray-900: #160042;
    --flowui-component-library-gray-500: #6b7094;
    --flowui-component-library-success-lighter: #ebfff6;
    --flowui-component-library-success-darker: #006638;
    --flowui-component-library-white: white;
    --flowui-component-library-gray-700: #282556;
    --flowui-component-library-grey-300: #e4e6f1;
    --flowui-component-library-gray-400: #b6b9ce;
    --flowui-component-library-gray-200: #f3f5fb;
    --flowui-component-library-gray-100: #f7f9fd;
    --lynx-black: #1f2022;
    --lynx-grey: #797c83;
    --lynx-medium-purple: #8979ec;
    --lynx-white: white;
    --lynx-deep-pink: #fd4b8b;
    --elements-webflow-library-neutral--100: white;
    --elements-webflow-library-neutral--800: #211f54;
    --elements-webflow-library-accent--primary-1: #4a3aff;
    --elements-webflow-library-neutral--300: #eff0f6;
    --elements-webflow-library-neutral--600: #6e7191;
    --flowui-component-library-gray-800: #1b114a;
    --flowui-component-library-primary-dark: #2d0896;
    --flowui-component-library-primary-base: #642eff;
    --black: black;
    --white: white;
    --elements-webflow-library-neutral--400: #dcddeb;
    --elements-webflow-library-general--shadow-01: rgba(20, 20, 43, .06);
    --elements-webflow-library-secondary--color-1: #281ca5;
    --alley-component-library--blue--900: #5167f6;
    --alley-component-library--neutral--off-white: #f4f4f6;
    --alley-component-library--blue--100: #eceefe;
    --alley-component-library--blue--700: #7788f8;
    --alley-component-library--gray--500: #5e606e;
}

.w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.w-checkbox {
    margin-bottom: 5px;
    padding-left: 20px;
    display: block;
}

.w-checkbox:before {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-checkbox:after {
    content: " ";
    clear: both;
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-checkbox-input {
    float: left;
    margin: 4px 0 0 -20px;
    line-height: normal;
}

.w-checkbox-input--inputType-custom {
    width: 12px;
    height: 12px;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec;
}

.w-layout-blockcontainer {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.a-header-block {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.a-header-content {
    color: #1a1b1f;
}

.a-margin-bottom-16 {
    margin-bottom: 16px;
}

.a-detail-small {
    letter-spacing: .08em;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.8;
}

.a-detail-small.a-text-color-primary {
    color: var(--lynx-grey);
}


.a-header-grid-image {
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    box-shadow: -1px 0 20px #0000000d;
}

@media screen and (max-width: 991px) {
    .w-layout-blockcontainer {
        max-width: 728px;
    }

    .a-header-image-grid {
        padding-left: 5%;
    }

    .a-header-logo-wrapper {
        margin-top: 60px;
    }

    .a-header-block {
        text-align: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 60px;
    }

    .a-header-section {
        padding-bottom: 0;
    }

    .brix---heading-h3-size-3 {
        font-size: 22px;
        line-height: 32px;
    }

    .brix---3-columns-1-col-tablet {
        grid-template-columns: 1fr;
    }

    .brix---mg-bottom-160px {
        margin-bottom: 100px;
    }

    .brix---heading-h1-size-2 {
        font-size: 48px;
        line-height: 60px;
    }

    .button-5 {
        left: -19px;
    }

    .brix---section-position-relative {
        padding-top: 118px;
        padding-bottom: 118px;
    }

}

@media screen and (max-width: 767px) {
    .w-layout-blockcontainer {
        max-width: none;
    }


    .a-header-image-grid {
        margin-top: 20px;
    }

    .a-header-logo-wrapper {
        flex-direction: column;
    }

    .a-header-section {
        padding-top: 72px;
        padding-bottom: 72px;
    }

    .brix---text-200-bold-3, .brix---text-200 {
        font-size: 16px;
        line-height: 18px;
    }

    .brix---square-icon {
        border-radius: 12px;
        max-width: 56px;
    }

    .brix---3-columns-1-col-tablet {
        grid-template-columns: 1fr;
    }

    .brix---mg-bottom-160px {
        margin-bottom: 80px;
    }

    .brix---mg-bottom-40px-2 {
        margin-bottom: 24px;
    }

    .brix---heading-h1-size-2 {
        margin-bottom: 12px;
        font-size: 38px;
        line-height: 46px;
    }

    .brix---section-position-relative {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

body {
    color: #1a1b1f;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

h1 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 44px;
    font-weight: 400;
    line-height: 62px;
}

h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
}

h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 400;
    line-height: 46px;
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
}

h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
}

h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
}

p {
    margin-bottom: 10px;
}

a {
    color: #1a1b1f;
    text-decoration: underline;
    transition: opacity .2s;
    display: block;
}

a:hover {
    color: #32343a;
}

a:active {
    color: #43464d;
}

ul {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 40px;
    list-style-type: disc;
}

li {
    margin-bottom: 10px;
}

img {
    display: block;
}

label {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

blockquote {
    border-left: 5px solid #e2e2e2;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 15px 30px;
    font-size: 20px;
    line-height: 34px;
}

figcaption {
    opacity: 1;
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    line-height: 26px;
}

.divider {
    height: 1px;
    background-color: #eee;
}

.styleguide-content-wrap {
    text-align: center;
}

.section {
    margin-left: 30px;
    margin-right: 30px;
}

.section.cc-cta {
    background-color: #f4f4f4;
    padding-left: 80px;
    padding-right: 80px;
}

.section.cc-store-home-wrap {
    height: auto;
}

.styleguide-block {
    text-align: left;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 80px;
    display: block;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.paragraph-tiny {
    font-size: 12px;
    line-height: 20px;
}

.paragraph-bigger {
    opacity: 1;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
}

.paragraph-bigger.cc-bigger-light {
    opacity: .6;
}

.paragraph-bigger.cc-bigger-white-light {
    opacity: .6;
    color: rgba(50, 51, 46, .84);
    font-weight: 500;
}

.button {
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #1a1b1f;
    border-radius: 0;
    padding: 12px 25px;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    transition: background-color .4s, opacity .4s, color .4s;
}

.button:hover {
    color: #fff;
    background-color: #32343a;
}

.button:active {
    background-color: #43464d;
}

.button.cc-jumbo-button {
    padding: 16px 35px;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
}

.button.cc-jumbo-button.cc-jumbo-white {
    color: #000;
    background-color: #fff;
    border-radius: 100px;
    margin-top: 122px;
    margin-bottom: -6px;
    padding-top: 16px;
}

.button.cc-jumbo-button.cc-jumbo-white:hover {
    background-color: #cecfc3;
}

.button.cc-jumbo-button.cc-jumbo-white:active {
    background-color: #e1e1e1;
}

.button.cc-contact-us {
    z-index: 5;
    border-radius: 100px;
    position: relative;
}

.button.cc-contact-us:hover {
    color: #10100f;
    mix-blend-mode: normal;
    background-color: #cecfc3;
}

.button.cc-white-button {
    color: #202020;
    background-color: #fff;
    padding: 16px 35px;
    font-size: 14px;
    line-height: 26px;
}

.button.cc-white-button:hover {
    background-color: rgba(255, 255, 255, .8);
}

.button.cc-white-button:active {
    background-color: rgba(255, 255, 255, .9);
}

.label {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

.label.cc-styleguide-label {
    margin-bottom: 25px;
}

.label.cc-light {
    opacity: .6;
}

.label.cc-blog-date {
    opacity: .6;
    margin-top: 20px;
}

.heading-jumbo-tiny {
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
}

.heading-jumbo-2 {
    color: var(--flowui-component-library-gray-200);
    text-transform: none;
    mix-blend-mode: normal;
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 55px;
    font-family: Oswald, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 80px;
}

.rich-text {
    width: 70%;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

.rich-text p {
    opacity: .6;
    margin-top: 15px;
    margin-bottom: 25px;
}

.rich-text figcaption {
    opacity: .6;
}

.rich-text figure {
    margin-top: 25px;
    padding-bottom: 20px;
}

.paragraph-light {
    opacity: .6;
}

.heading-jumbo {
    color: rgba(50, 51, 46, .84);
    text-transform: none;
    mix-blend-mode: normal;
    margin-top: 3px;
    margin-bottom: 10px;
    padding-left: 28px;
    font-family: Oswald, sans-serif;
    font-size: 64px;
    font-weight: 400;
    line-height: 80px;
}

.heading-jumbo-small {
    text-transform: none;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
}

.styleguide-button-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
}

.styleguide-header-wrap {
    height: 460px;
    color: #fff;
    text-align: center;
    background-color: #1a1b1f;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    display: flex;
}

.paragraph-small {
    font-size: 14px;
    line-height: 26px;
}

.logo-link {
    z-index: 1;
}

.logo-link:hover {
    opacity: .8;
}

.logo-link:active {
    opacity: .7;
}

.menu {
    z-index: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.navigation-wrap {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
}

.navigation {
    background-color: rgba(0, 0, 0, 0);
    align-items: center;
    padding: 30px 50px;
    display: flex;
}

.navigation-item {
    opacity: .6;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

.navigation-item:hover {
    opacity: .9;
}

.navigation-item:active {
    opacity: .8;
}

.navigation-item.w--current {
    opacity: 1;
    color: #1a1b1f;
    font-weight: 600;
}

.navigation-item.w--current:hover {
    opacity: .8;
    color: #32343a;
}

.navigation-item.w--current:active {
    opacity: .7;
    color: #32343a;
}

.logo-image {
    display: block;
}

.navigation-items {
    flex: none;
    justify-content: center;
    align-items: center;
    display: flex;
}

.footer-wrap {
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
}

.webflow-link {
    opacity: .5;
    align-items: center;
    text-decoration: none;
    transition: opacity .4s;
    display: flex;
}

.webflow-link:hover {
    opacity: 1;
}

.webflow-link:active {
    opacity: .8;
}

.webflow-logo-tiny {
    margin-top: -2px;
    margin-right: 8px;
}

.cta-text {
    width: 70%;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
}

.cta-wrap {
    text-align: center;
    justify-content: center;
    padding-top: 110px;
    padding-bottom: 120px;
    display: flex;
}

.intro-header {
    height: 620px;
    color: #fff;
    background-color: #f4f4f4;
    background-image: url('../images/iStock-1442447917 (3) (1) (1).jpg');
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    margin: unset !important;
    padding: unset !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center;
    position: relative;
}

.intro-header a {
    position: relative;
    margin: unset !important;
    width: min(50vw, 200px);
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: 50px !important;
}

.intro-header.cc-subpage {
    height: 480px;
    color: #fff;
    background-color: #000;
    background-image: none;
}

.intro-content {
    width: 70%;
    max-width: 1140px;
    text-align: center;
}

.intro-content.cc-homepage {
    margin-bottom: 20px;
}

.motto-wrap {
    width: 80%;
    text-align: center;
    margin-bottom: 85px;
    margin-left: auto;
    margin-right: auto;
}

.about-story-wrap {
    width: 80%;
    text-align: center;
    margin: 80px auto;
}

.our-services-grid {
    grid-column-gap: 80px;
    grid-row-gap: 60px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . ."
                       ". . .";
    margin-bottom: 120px;
}

.section-heading-wrap {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
}

.service-icon {
    margin-bottom: 30px;
}

.status-message {
    color: #fff;
    text-align: center;
    background-color: #202020;
    padding: 9px 30px;
    font-size: 14px;
    line-height: 26px;
}

.status-message.cc-success-message {
    background-color: #12b878;
}

.status-message.cc-error-message {
    background-color: #db4b68;
}

.status-message.cc-no-data {
    width: 70%;
    margin: 100px auto;
    display: block;
}

.contact-form-wrap {
    border: 1px solid #eee;
    padding: 45px 50px 50px;
}

.contact-form-grid {
    grid-column-gap: 80px;
    grid-row-gap: 30px;
    grid-template: ". ."
  / 2.5fr 1fr;
    align-items: start;
    margin-top: 100px;
    margin-bottom: 100px;
}

.details-wrap {
    margin-bottom: 30px;
}

.get-in-touch-form {
    flex-direction: column;
    display: flex;
}

.text-field {
    border: 1px solid #e4e4e4;
    border-radius: 0;
    margin-bottom: 18px;
    padding: 21px 20px;
    font-size: 14px;
    line-height: 26px;
    transition: border-color .4s;
}

.text-field:hover {
    border-color: #e3e6eb;
}

.text-field:active, .text-field:focus {
    border-color: #43464d;
}

.text-field::-ms-input-placeholder {
    color: rgba(50, 52, 58, .4);
}

.text-field::placeholder {
    color: rgba(50, 52, 58, .4);
}

.text-field.cc-contact-field {
    margin-bottom: 25px;
}

.text-field.cc-textarea {
    height: 200px;
    padding-top: 12px;
}

.contact-form {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.contact-form-heading-wrap {
    margin-bottom: 40px;
}

.contact-heading {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 400;
}

.map {
    height: 460px;
    margin-bottom: 100px;
    line-height: 20px;
}

.contact-team-name-wrap {
    margin-bottom: 30px;
}

.our-contacts {
    grid-column-gap: 80px;
    grid-row-gap: 60px;
    text-align: center;
    grid-template: ". . ."
  / 1fr 1fr 1fr;
    margin-bottom: 120px;
}

.contact-team-details-wrap {
    margin-top: 30px;
}

.contact-team-pic {
    height: 150px;
    background-color: #f4f4f4;
    margin-bottom: 30px;
}

.team-pic {
    width: 100%;
    height: 420px;
    background-color: #f4f4f4;
    margin-bottom: 40px;
}

.team-members {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . ."
                       ". . .";
    margin-bottom: 60px;
}

.team-member-title-wrap {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    display: flex;
}

.team-member-name {
    opacity: 1;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
}

.projects-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template: "."
                 "."
                 "."
                 / 1fr;
    margin-bottom: 60px;
}

.project-name-wrap {
    width: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    display: flex;
}

.project-name-link {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-decoration: none;
}

.project-name-link:hover {
    opacity: .8;
}

.project-name-link:active {
    opacity: .7;
}

.project-cover-link {
    margin-bottom: 40px;
}

.project-cover-link:hover {
    opacity: .8;
}

.project-cover-link:active {
    opacity: .7;
}

.project-overview-header {
    height: 620px;
    color: #fff;
    background-color: #f4f4f4;
    background-image: url('../images/portfolio-1---wide.svg');
    background-position: 50%;
    background-size: cover;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    display: flex;
}

.project-overview-header.cc-project-2-header {
    background-image: url('../images/portfolio-2---wide.svg');
    background-position: 50%;
}

.project-overview-header.cc-project-3-header {
    background-image: url('../images/portfolio-3---wide.svg');
    background-size: cover;
}

.project-details-grid {
    grid-column-gap: 80px;
    grid-row-gap: 30px;
    grid-template: ". ."
  / 1fr 2fr;
    align-items: start;
    margin-top: 100px;
    margin-bottom: 100px;
}

.project-pics {
    margin-bottom: 120px;
}

.detail-image {
    width: 100%;
    margin-bottom: 30px;
}

.blog-detail-header-wrap {
    width: 70%;
    margin: 60px auto;
}

.detail-header-image {
    width: 100%;
    height: 620px;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
    background-position: 0 0;
    background-size: cover;
    margin-bottom: 60px;
    display: block;
}

.detail-header-image.w--current {
    margin-bottom: 60px;
}

.blog-list-wrap {
    margin-bottom: 100px;
}

.blog-item {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.blog-preview-image {
    width: 100%;
    height: 620px;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
    background-position: 0 0;
    background-size: cover;
    margin-bottom: 45px;
    transition: opacity .6s;
    display: block;
}

.blog-preview-image:hover {
    opacity: .8;
}

.blog-preview-image:active {
    opacity: .7;
}

.blog-summary-wrap {
    width: 70%;
    text-align: left;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.blog-heading-link {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 400;
    line-height: 46px;
    text-decoration: none;
}

.blog-heading-link:hover {
    opacity: .8;
}

.blog-heading-link:active {
    opacity: .7;
}

.contact-email-link {
    opacity: .6;
    margin-bottom: 5px;
}

.contact-email-link:hover {
    opacity: 1;
}

.contact-email-link:active {
    opacity: .8;
}

.protected-form {
    flex-direction: column;
    display: flex;
}

.protected-wrap {
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 100px;
    display: flex;
}

.protected-heading {
    margin-bottom: 30px;
}

.utility-page-wrap {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px;
    display: flex;
}

._404-wrap {
    width: 100%;
    height: 100%;
    background-color: #1a1b1f;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    display: flex;
}

._404-content-wrap {
    margin-bottom: 20px;
}

.home-content-wrap {
    margin-top: 80px;
    margin-bottom: 120px;
}

.home-section-wrap {
    margin-bottom: 30px;
}

.section-heading {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
}

.about-grid {
    grid-column-gap: 80px;
    grid-row-gap: 30px;
    grid-template: ". ."
  / 1fr 2fr;
    align-items: center;
    margin-bottom: 80px;
}

.about-grid.cc-about-2 {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: ". .";
}

.intro-text {
    width: 80%;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
}

.collection-wrap {
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
}

.work-heading {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
}

.blog-heading {
    text-align: center;
    margin-bottom: 60px;
}

.blog-preview-wrap {
    width: 33.33%;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 45px;
}

.collection-list-wrapper {
    margin-bottom: 120px;
}

.business-article-heading {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    text-decoration: none;
}

.business-article-heading:hover {
    opacity: .8;
}

.business-article-heading:active {
    opacity: .7;
}

._w-lightbox-caption {
    background-color: rgba(0, 0, 0, .6);
}

.body {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.team-circles {
    border-bottom: 1px solid #e4ebf3;
    padding: 80px 30px;
    position: relative;
}

.container-2 {
    width: 100%;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.centered-heading {
    text-align: center;
    margin-bottom: 16px;
}

.centered-subheading {
    max-width: 530px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.team-grid {
    grid-column-gap: 64px;
    grid-row-gap: 56px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 50px;
    display: grid;
}

.team-card {
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    display: flex;
}

.team-member-image {
    width: 270px;
    height: 270px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 24px;
}

.team-member-name-2 {
    margin-bottom: 6px;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
}

.team-member-position {
    margin-bottom: 24px;
}

.efi-accordion-paragraph {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
}

.efi-ft-09-content {
    overflow: hidden;
}

.efi-ft-09-bar {
    color: #1a1e27;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    text-decoration: none;
    display: flex;
}

.efi-ft-09-accordion-wrap {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
}

.efi-ft-09-accordion-bottom-bar {
    width: 100%;
    height: 1px;
}

.efi-ft-09-accordion {
    margin-left: auto;
    margin-right: auto;
}

.efi-paragraph {
    text-align: center;
}

.efi-ft-09-grid {
    grid-column-gap: 75px;
    grid-template-rows: auto;
    margin-bottom: 75px;
}

.efi-ft-09-container {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.button-5 {
    color: #fff;
    background-color: #000;
    border-radius: 100px;
    margin-top: 0;
    margin-left: 0;
    padding-top: 9px;
    font-size: 24px;
    transition: opacity .125s;
    position: relative;
    top: -320px;
    left: 304px;
}

.button-5:hover {
    transform: scale(1.2);
}

.efi-ft-09 {
    padding: 100px 3%;
}

.efi-tsm-02-title {
    color: #2b2b2b;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.efi-tsm-02-testimonial-title {
    grid-column-gap: .2em;
    grid-row-gap: .2em;
    flex-direction: column;
    display: flex;
}

.efi-tsm-02-icon {
    width: 20%;
}

.efi-tsm-02-testimonial-top {
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    align-items: center;
    display: flex;
}

.efi-tsm-02-testimonial {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    background-color: #fff;
    border-radius: 1.1em;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 20px;
    display: flex;
}

.efi-tsm-02-div-3 {
    width: 33%;
    padding-left: 10px;
}

.efi-tsm-02-div-2 {
    width: 33%;
    padding-left: 10px;
    padding-right: 10px;
}

.efi-tsm-02-div-1 {
    width: 33%;
    padding-right: 10px;
}

.efi-tsm-02-testimonials {
    flex-wrap: nowrap;
    display: flex;
}

.efi-tsm-02-title-wrapper {
    color: #fff;
}

.efi-tsm-02-grid {
    grid-column-gap: 50px;
    grid-template-rows: auto;
    margin-bottom: 100px;
}

.efi-tsm-02-container {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.efi-tsm-02 {
    background-color: #000;
    padding: 10% 5%;
}

.efi-tsm-02-div-4 {
    width: 33%;
    padding-left: 10px;
    padding-right: 10px;
}

.efi-tsm-02-div-5 {
    width: 33%;
    padding-right: 10px;
}

.efi-tsm-02-div-6 {
    width: 33%;
    padding-left: 10px;
}

.efi-tsm-02-div-7 {
    width: 33%;
    padding-left: 10px;
    padding-right: 10px;
}

.efi-tsm-02-div-8 {
    width: 33%;
    padding-right: 10px;
}

.efi-h2-5 {
    text-align: center;
}

.efi-tsm-01-logos-wrapper {
    text-align: right;
}

/*.efi-tsm-01-logos-wrapper img:nth-child(4){*/
/*  grid-column: 3 / 3!important;*/
/*}*/
/*.efi-tsm-01-logos-wrapper img:nth-child(5){*/
/*  grid-column: 3 / 3!important;*/
/*}*/
.efi-tsm-01-logo-inner {
    justify-content: center;
    align-items: center;
    display: flex;
}

.efi-tsm-01-logos-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}

.efi-tsm-01-grid {
    grid-column-gap: 75px;
    grid-row-gap: 50px;
    grid-template-rows: auto;
}

.efi-tsm-01-container {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.efi-tsm-01 {
    padding: 10% 3%;
}

.footer-subscribe {
    border-bottom: 1px solid #e4ebf3;
    margin-top: -125px;
    padding: 50px 30px 40px;
    position: relative;
    top: 171px;
}

.container-3 {
    width: 100%;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.footer-form-two {
    background-color: #f5f7fa;
    margin-bottom: 40px;
    padding: 64px;
}

.footer-form-container-two {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.footer-form-title {
    font-size: 24px;
    line-height: 32px;
}

.footer-form-block-two {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    position: relative;
}

.footer-form-input {
    width: 460px;
    height: 48px;
    border: 1px #000;
    margin-bottom: 0;
    padding: 12px 180px 12px 20px;
}

.footer-form-input::-ms-input-placeholder {
    color: rgba(26, 27, 31, .8);
}

.footer-form-input::placeholder {
    color: rgba(26, 27, 31, .8);
}

.button-primary {
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #1a1b1f;
    padding: 12px 25px;
    font-size: 12px;
    line-height: 20px;
    transition: all .2s;
}

.button-primary:hover {
    color: #fff;
    background-color: #32343a;
}

.button-primary:active {
    background-color: #43464d;
}

.button-primary.footer-form-button {
    padding: 8px 18px;
    position: absolute;
    right: 8px;
}

.footer-wrapper-three {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.footer-block-three {
    justify-content: flex-start;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
}

.footer-link-three {
    color: #1a1b1f;
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: none;
}

.footer-link-three:hover {
    color: rgba(26, 27, 31, .75);
}

.footer-social-block-three {
    justify-content: flex-start;
    align-items: center;
    margin-left: -32px;
    display: flex;
}

.footer-social-link-three {
    margin-left: 32px;
}

.footer-divider-two {
    width: 100%;
    height: 1px;
    background-color: #e4ebf3;
    margin-top: 40px;
    margin-bottom: 40px;
}

.footer-bottom {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.footer-copyright {
    color: #3a4554;
}

.footer-legal-block {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.footer-legal-link {
    color: #3a4554;
    margin-left: 24px;
}

.footer-legal-link:hover {
    color: #3a4554;
}

.f-account-link {
    color: var(--flowui-component-library-gray-900);
    font-weight: 700;
    text-decoration: none;
}

.f-paragraph-small {
    letter-spacing: -.01em;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.8;
}

.f-paragraph-small.f-text-color-gray-500 {
    color: var(--flowui-component-library-gray-500);
}

.f-success-message {
    background-color: var(--flowui-component-library-success-lighter);
    color: var(--flowui-component-library-success-darker);
    letter-spacing: -.03em;
    font-size: 14px;
    line-height: 24px;
}

.f-button-neutral {
    min-height: 48px;
    background-color: var(--flowui-component-library-gray-900);
    color: var(--flowui-component-library-white);
    text-align: center;
    letter-spacing: -.02em;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: background-color .3s;
    display: flex;
}

.f-button-neutral:hover {
    background-color: var(--flowui-component-library-gray-700);
}

.f-account-form-button {
    flex-direction: column;
    margin-top: 32px;
    display: flex;
}

.f-field-input {
    min-height: 48px;
    border: 1px solid var(--flowui-component-library-grey-300);
    color: #000;
    border-radius: 8px;
    margin-bottom: 0;
    padding: 12px 16px;
    transition: all .2s;
}

.f-field-input:focus {
    border-color: var(--flowui-component-library-gray-400);
}

.f-field-input::-ms-input-placeholder {
    color: var(--flowui-component-library-gray-500);
}

.f-field-input::placeholder {
    color: var(--flowui-component-library-gray-500);
}

.f-field-label {
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.01em;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.f-field-wrapper {
    flex-direction: column;
    align-items: flex-start;
}

.f-account-input-grid {
    grid-template-columns: 1fr;
}

.f-account-form-block {
    margin-bottom: 42px;
}

.f-text-color-gray-500 {
    color: var(--flowui-component-library-gray-500);
}

.f-margin-bottom-24 {
    margin-bottom: 24px;
}

.f-icon-regular {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    transition: color .2s;
    display: flex;
}

.f-account-social-icon {
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    border: 1px solid var(--flowui-component-library-gray-200);
    color: var(--flowui-component-library-gray-900);
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    transition: background-color .3s;
    display: flex;
}

.f-account-social-icon:hover {
    background-color: var(--flowui-component-library-gray-200);
}

.f-account-social-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 40px;
    display: flex;
}

.f-paragraph-regular {
    letter-spacing: -.02em;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.8;
}

.f-h5-heading {
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 1.4;
}

.f-margin-bottom-08 {
    margin-bottom: 8px;
}

.f-account-content-wrapper {
    width: 100%;
    max-width: 400px;
}

.f-account-container-r {
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 5%;
    display: flex;
    position: relative;
}

.f-image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.f-account-image-wrapper {
    width: 50%;
    background-color: var(--flowui-component-library-gray-200);
}

.f-account-section {
    height: 100vh;
    min-height: 900px;
    display: flex;
    position: relative;
}

.f-checkbox-label {
    color: var(--flowui-component-library-gray-900);
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
}

.f-checkbox {
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-color: var(--flowui-component-library-gray-400);
    background-color: var(--flowui-component-library-white);
    border-radius: 4px;
    margin: 0 8px 0 0;
}

.f-checkbox.w--redirected-checked {
    background-color: var(--flowui-component-library-gray-900);
    background-size: 12px;
    border-style: none;
    border-radius: 4px;
}

.f-checkbox.w--redirected-focus {
    box-shadow: none;
}

.f-checkbox-field {
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
}

.f-margin-bottom-25 {
    margin-bottom: 24px;
}

.f-account-container-r-2 {
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 5%;
    display: flex;
    position: relative;
}

.f-image-cover-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.f-account-link-2 {
    color: var(--flowui-component-library-gray-900);
    font-weight: 700;
    text-decoration: none;
}

.f-paragraph-small-2 {
    letter-spacing: -.01em;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.8;
}

.f-paragraph-small-2.f-text-color-gray-500 {
    color: var(--flowui-component-library-gray-500);
}

.f-success-message-2 {
    background-color: var(--flowui-component-library-success-lighter);
    color: var(--flowui-component-library-success-darker);
    letter-spacing: -.03em;
    font-size: 14px;
    line-height: 24px;
}

.f-button-neutral-2 {
    min-height: 48px;
    background-color: var(--flowui-component-library-gray-900);
    color: var(--flowui-component-library-white);
    text-align: center;
    letter-spacing: -.02em;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: background-color .3s;
    display: flex;
}

.f-button-neutral-2:hover {
    background-color: var(--flowui-component-library-gray-700);
}

.f-account-form-button-2 {
    flex-direction: column;
    margin-top: 32px;
    display: flex;
}

.f-checkbox-label-2 {
    color: var(--flowui-component-library-gray-900);
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
}

.f-checkbox-2 {
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-color: var(--flowui-component-library-gray-400);
    background-color: var(--flowui-component-library-white);
    border-radius: 4px;
    margin: 0 8px 0 0;
}

.f-checkbox-2.w--redirected-checked {
    background-color: var(--flowui-component-library-gray-900);
    background-size: 12px;
    border-style: none;
    border-radius: 4px;
}

.f-checkbox-2.w--redirected-focus {
    box-shadow: none;
}

.f-checkbox-field-2 {
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
}

.f-field-input-2 {
    min-height: 48px;
    border: 1px solid var(--flowui-component-library-grey-300);
    color: #000;
    border-radius: 8px;
    margin-bottom: 0;
    padding: 12px 16px;
    transition: all .2s;
}

.f-field-input-2:focus {
    border-color: var(--flowui-component-library-gray-400);
}

.f-field-input-2::-ms-input-placeholder {
    color: var(--flowui-component-library-gray-500);
}

.f-field-input-2::placeholder {
    color: var(--flowui-component-library-gray-500);
}

.f-field-label-2 {
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.01em;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.f-field-wrapper-2 {
    flex-direction: column;
    align-items: flex-start;
}

.f-account-input-grid-2 {
    grid-template-columns: 1fr;
}

.f-account-form-block-2 {
    margin-bottom: 42px;
}

.f-text-color-gray-501 {
    color: var(--flowui-component-library-gray-500);
}

.f-margin-bottom-26 {
    margin-bottom: 24px;
}

.f-icon-regular-2 {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    transition: color .2s;
    display: flex;
}

.f-account-social-icon-2 {
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    border: 1px solid var(--flowui-component-library-gray-200);
    color: var(--flowui-component-library-gray-900);
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    transition: background-color .3s;
    display: flex;
}

.f-account-social-icon-2:hover {
    background-color: var(--flowui-component-library-gray-200);
}

.f-account-social-wrapper-2 {
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 40px;
    display: flex;
}

.f-paragraph-regular-2 {
    letter-spacing: -.02em;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.8;
}

.f-h5-heading-2 {
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 1.4;
}

.f-margin-bottom-27 {
    margin-bottom: 8px;
}

.f-account-content-wrapper-2 {
    width: 100%;
    max-width: 400px;
}

.f-account-container-r-3 {
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 5%;
    display: flex;
    position: relative;
}

.f-image-cover-3 {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.f-account-image-wrapper-2 {
    width: 50%;
    background-color: var(--flowui-component-library-gray-200);
}

.f-account-section-2 {
    height: 100vh;
    min-height: 900px;
    display: flex;
    position: relative;
}

.f-button-secondary {
    min-height: 48px;
    border: 1px solid var(--flowui-component-library-grey-300);
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.02em;
    background-color: #fff;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: border-color .3s, background-color .3s;
    display: flex;
}

.f-button-secondary:hover {
    background-color: var(--flowui-component-library-gray-200);
    border-color: rgba(0, 0, 0, 0);
}

.f-button-neutral-3 {
    min-height: 48px;
    background-color: var(--flowui-component-library-gray-900);
    color: var(--flowui-component-library-white);
    text-align: center;
    letter-spacing: -.02em;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: background-color .3s;
    display: flex;
}

.f-button-neutral-3:hover {
    background-color: var(--flowui-component-library-gray-700);
}

.f-header-button-middle {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.f-paragraph-large {
    letter-spacing: -.02em;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 32px;
}

.f-margin-bottom-40 {
    margin-bottom: 40px;
}

.f-h1-heading {
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.04em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 64px;
    line-height: 1.2;
}

.f-margin-bottom-41 {
    margin-bottom: 24px;
}

.f-heading-detail-small {
    color: var(--flowui-component-library-gray-500);
    letter-spacing: .08em;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
}

.f-margin-bottom-42 {
    margin-bottom: 8px;
}

.f-header-title-wrapper-center {
    z-index: 5;
    width: 100%;
    max-width: 630px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
}

.f-container-regular {
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.f-section-large {
    background-image: url('../images/iStock-1477970232 (2).jpg');
    background-position: 0 0;
    background-size: cover;
    padding: 92px 5%;
    position: relative;
}

.f-section-large.druga {
    background-image: none;
    background-position: 0 0;
    background-size: auto;
}

.f-paragraph-small-3 {
    letter-spacing: -.01em;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.8;
}

.f-sub-heading-large {
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.02em;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.f-margin-bottom-43 {
    margin-bottom: 8px;
}

.f-feature-icon-wrapper {
    width: 72px;
    height: 72px;
    min-height: 72px;
    min-width: 72px;
    background-color: var(--flowui-component-library-gray-100);
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 16px;
}

.f-grid-three-column {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
}

.f-h3-heading {
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 48px;
    line-height: 1.2;
}

.f-margin-bottom-16 {
    margin-bottom: 16px;
}

.f-heading-detail-small-2 {
    color: var(--flowui-component-library-gray-500);
    letter-spacing: .08em;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
}

.f-title-wrapper-center {
    z-index: 5;
    width: 100%;
    max-width: 580px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
}

.f-margin-bottom-128 {
    margin-bottom: 128px;
}

.lynx-arrow-features {
    width: 55px;
    height: 55px;
    background-color: var(--lynx-black);
    background-image: url('../images/Arrow_2.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 50%;
    flex: none;
    position: absolute;
    top: -25px;
    bottom: auto;
    left: auto;
    right: auto;
}

.lynx-image-features {
    width: 100%;
    min-height: 300px;
    object-fit: cover;
    border-radius: 50% 50% 30px 30px;
}

.lynx-link-block-features {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: filter .4s;
    display: flex;
    position: relative;
}

.lynx-link-block-features:hover {
    filter: brightness(112%);
}

.lynx-paragraph {
    max-width: 820px;
    color: var(--lynx-grey);
    margin-bottom: 36px;
    font-family: Varela, sans-serif;
    font-size: 18px;
    line-height: 165%;
}

.lynx-heading-features-large {
    color: var(--lynx-black);
    margin-top: 0;
    margin-bottom: 30px;
    font-family: PT Serif, serif;
    font-size: 37px;
    font-weight: 400;
    line-height: 122%;
}

.lynx-subtitle-productivity {
    color: var(--lynx-medium-purple);
    letter-spacing: 1.3px;
    text-transform: uppercase;
    background-color: rgba(137, 121, 236, .1);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 7px 12px;
    font-family: Varela, sans-serif;
    font-size: 12px;
    line-height: 140%;
    display: inline-block;
}

.lynx-block-features-large {
    padding: 55px 45px 35px;
}

.lynx-features-large {
    background-color: var(--lynx-white);
    text-align: center;
    border-radius: 30px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    box-shadow: 0 10px 15px -10px rgba(31, 32, 34, .1);
}

.lynx-subtitle-software {
    color: var(--lynx-deep-pink);
    letter-spacing: 1.3px;
    text-transform: uppercase;
    background-color: rgba(253, 75, 139, .1);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 7px 12px;
    font-family: Varela, sans-serif;
    font-size: 12px;
    line-height: 140%;
    display: inline-block;
}

.lynx-grid-2-columns {
    width: 100%;
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    margin-top: 14px;
}

.lynx-content {
    width: 100%;
    max-width: 1080px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.brix---bg-overlay-gradient {
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, .56) 61%, #000);
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.brix---card-image {
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.brix---link-icon-right {
    margin-left: 6px;
}

.brix---text-200-bold {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
}

.brix---color-neutral-100 {
    color: var(--elements-webflow-library-neutral--100);
}

.brix---link-wrapper {
    color: var(--elements-webflow-library-neutral--800);
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
    transition: color .3s;
    display: flex;
}

.brix---link-wrapper:hover {
    color: var(--elements-webflow-library-accent--primary-1);
}

.brix---paragraph-default {
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 18px;
    line-height: 30px;
}

.brix---color-neutral-300 {
    color: var(--elements-webflow-library-neutral--300);
}

.brix---mg-bottom-32px {
    margin-bottom: 32px;
}

.brix---heading-h3-size {
    margin-top: 0;
    margin-bottom: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
}

.brix---mg-bottom-8px {
    margin-bottom: 8px;
}

.brix---card-link-img-bg {
    min-height: 500px;
    border-radius: 24px;
    align-items: flex-end;
    padding: 48px;
    text-decoration: none;
    transition: transform .3s;
    display: flex;
    position: relative;
    overflow: hidden;
}

.brix---card-link-img-bg:hover {
    transform: scale(.96);
}

.brix---grid-2-columns---gap-row-72px {
    grid-column-gap: 28px;
    grid-row-gap: 72px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: grid;
}

.brix---color-neutral-600 {
    color: var(--elements-webflow-library-neutral--600);
}

.brix---heading-h2-size {
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
}

.brix---color-neutral-800 {
    color: var(--elements-webflow-library-neutral--800);
}

.brix---mg-bottom-12px {
    margin-bottom: 12px;
}

.brix---text-center {
    text-align: center;
}

.heading-4 {
    text-align: center;
    text-decoration: underline;
}

.brix---inner-container-600px---center {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.brix---mg-bottom-48px {
    margin-bottom: 48px;
}

.brix---container-default {
    max-width: 1218px;
    padding-left: 24px;
    padding-right: 24px;
}

.brix---section {
    color: var(--elements-webflow-library-neutral--100);
    padding-top: 176px;
    padding-bottom: 176px;
}

.f-button-secondary-2 {
    min-height: 48px;
    border: 1px solid var(--flowui-component-library-grey-300);
    color: var(--flowui-component-library-gray-900);
    letter-spacing: -.02em;
    background-color: #fff;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: border-color .3s, background-color .3s;
    display: flex;
}

.f-button-secondary-2:hover {
    background-color: var(--flowui-component-library-gray-200);
    border-color: rgba(0, 0, 0, 0);
}

.f-button-neutral-4 {
    min-height: 48px;
    background-color: var(--flowui-component-library-gray-900);
    color: var(--flowui-component-library-white);
    text-align: center;
    letter-spacing: -.02em;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: background-color .3s;
    display: flex;
}

.f-button-neutral-4:hover {
    background-color: var(--flowui-component-library-gray-700);
}

.f-header-button-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    text-align: center;
    flex-wrap: wrap;
    align-content: stretch;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: unset !important;
}

.f-paragraph-large-2 {
    text-align: center;
    letter-spacing: -.02em;
    margin-bottom: 0;
    margin-left: 18px;
    font-size: 18px;
    line-height: 32px;
}

.f-margin-bottom-129 {
    margin-bottom: 40px;
}

.f-h1-heading-2 {
    color: var(--flowui-component-library-gray-900);
    text-align: center;
    letter-spacing: -.04em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 64px;
    line-height: 1.2;
}

.f-margin-bottom-130 {
    margin-bottom: 24px;
}

.f-heading-detail-small-3 {
    color: var(--flowui-component-library-gray-500);
    text-align: center;
    letter-spacing: .08em;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
}

.f-margin-bottom-131 {
    margin-bottom: 8px;
}

.f-header-wrapper-left {
    max-width: 620px;
}

.f-container-regular-2 {
    width: 100%;
    max-width: 1140px;
    margin-left: 180px;
    margin-right: auto;
    position: relative;
}

.f-section-large-2 {
    background-image: url('../images/iStock-1477970232 (2).jpg');
    background-position: 0 0;
    background-size: cover;
    padding: 92px 5%;
    position: relative;
}

.lynx-link-footer-large {
    color: var(--lynx-black);
    letter-spacing: 1.3px;
    text-transform: uppercase;
    padding-top: 7px;
    padding-bottom: 7px;
    font-family: Varela, sans-serif;
    font-size: 13px;
    line-height: 140%;
    text-decoration: none;
    transition: color .2s;
}

.lynx-link-footer-large:hover {
    color: var(--lynx-medium-purple);
}

.lynx-heading-footer {
    color: rgba(31, 32, 34, .5);
    letter-spacing: 1.3px;
    text-transform: uppercase;
    margin-bottom: 22px;
    font-family: Varela, sans-serif;
    font-size: 11px;
    line-height: 140%;
}

.lynx-block-footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.button-5 {
    color: #f4f4f4;
    background-color: #000;
    border-radius: 100px;
    font-size: 24px;
    position: relative;
    top: -318px;
    left: 103px;
}

.intro-header.cc-subpage {
    color: #fff;
    background-color: #000;
    background-image: url('../images/iStock-2077603968_1.webp');
    background-position: 50%;
    background-size: cover;
    height: 480px;
}

.lynx-link-footer {
    color: rgba(31, 32, 34, .5);
    letter-spacing: 1.3px;
    text-transform: uppercase;
    font-family: Varela, sans-serif;
    font-size: 11px;
    line-height: 140%;
    text-decoration: none;
    transition: color .2s;
}

.lynx-link-footer:hover {
    color: var(--lynx-black);
}

.lynx-logo-footer {
    width: 76px;
    margin-bottom: 16px;
}

.lynx-grid-footer {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-top: 1px solid #dfdad2;
    grid-template-rows: auto;
    grid-template-columns: 2.5fr 1fr 1fr 1fr;
    padding-top: 80px;
    padding-bottom: 80px;
}

.lynx-footer {
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.f-paragraph-small-4 {
    letter-spacing: -.01em;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.8;
}

.f-dropdown-icon {
    width: 24px;
    height: 24px;
    color: var(--flowui-component-library-gray-500);
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    display: flex;
}

.f-dropdown-link {
    color: var(--flowui-component-library-gray-800);
    letter-spacing: -.01em;
    border-radius: 4px;
    align-items: center;
    padding: 12px;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    transition: background-color .2s;
    display: flex;
}

.f-dropdown-link:hover, .f-dropdown-link:focus {
    background-color: var(--flowui-component-library-gray-200);
}

.f-dropdown-link.w--current {
    color: var(--flowui-component-library-primary-dark);
}

.f-dropdown-wrap {
    z-index: 999;
    height: auto;
    background-color: #fff;
    padding: 8px;
}

.f-dropdown-list.w--open {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 12px;
    overflow: hidden;
    box-shadow: 34px 34px 88px rgba(177, 186, 200, .16);
}

.f-dropdown-icon-l {
    color: var(--flowui-component-library-gray-500);
    margin-right: 16px;
    font-size: 14px;
}

.f-dropdown-toggle {
    width: 100%;
    border: 1px solid var(--flowui-component-library-grey-300);
    color: var(--flowui-component-library-gray-900);
    border-radius: 8px;
    justify-content: space-between;
    padding: 12px 16px;
    transition: border-color .3s;
    display: inline-block;
}

.f-dropdown-toggle:hover {
    border-color: var(--flowui-component-library-primary-base);
    outline-offset: 1px;
    outline: 2px solid rgba(100, 46, 255, .1);
}

.f-dropdown-toggle.w--open {
    border-color: var(--flowui-component-library-primary-base);
    outline-offset: 0px;
    outline: 2px solid rgba(100, 46, 255, .1);
}

.f-dropdown {
    width: 100%;
    max-width: 240px;
}

.select-field {
    color: #080808;
}

.submit-button {
    display: none;
    overflow: visible;
}

.select-field-2, .text-field-2, .select-field-3, .select-field-4, .select-field-5 {
    color: #000;
    border-radius: 20px;
}

.body-2 {
    color: #102889;
}

.select-field-7, .select-field-8 {
    color: #000;
    border-radius: 20px;
}

.heading {
    color: #1d1d1d;
    text-align: center;
    margin-top: 0;
    font-family: PT Serif, serif;
    font-weight: 400;
}

.text-field-3, .text-field-4, .text-field-5, .text-field-6, .text-field-7, .text-field-8, .text-field-9, .text-field-10, .text-field-11, .text-field-12, .text-field-13, .text-field-14, .text-field-15, .text-field-16, .text-field-17 {
    color: #000;
    border-radius: 20px;
}

.submit-button-2 {
    color: #fff;
    background-color: #000;
    border-radius: 100px;
    transition: all .25s;
}

.submit-button-2:hover {
    color: #000;
    background-color: #cecfc3;
}

.f-heading-detail-small-4 {
    color: var(--white);
    letter-spacing: .08em;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
}

.button-3 {
    color: #fafafa;
    background-color: #000;
    border-radius: 100px;
    margin-top: 11px;
    margin-left: 424px;
}

.button-3:hover {
    color: #000;
    background-color: #cecfc3;
}

.brix---btn-primary {
    background-color: var(--lynx-black);
    color: var(--white);
    text-align: center;
    transform-style: preserve-3d;
    border-radius: 48px;
    justify-content: center;
    padding: 26px 38px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
    transition: background-color .3s, transform .3s, color .3s;
}

.brix---btn-primary:hover {
    background-color: var(--lynx-grey);
    color: var(--black);
    transform: translate3d(0, -3px, .01px);
}

.f-h3-heading-2 {
    color: var(--white);
    letter-spacing: -.02em;
    margin-top: 0;
    margin-bottom: 0;
    font-family: PT Serif, serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
}

.brix---paragraph-default-2 {
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 18px;
    line-height: 30px;
}

.brix---color-neutral-801 {
    color: var(--elements-webflow-library-neutral--600);
}

.brix---mg-bottom-24px {
    margin-bottom: 24px;
}

.brix---heading-h4-size {
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
}

.brix---color-neutral-802 {
    color: var(--elements-webflow-library-neutral--800);
}

.brix---mg-bottom-16px {
    margin-bottom: 16px;
}

.brix---product-card-content {
    padding: 32px 32px 48px;
}

.brix---badge-secondary-small-bold {
    border: 1px solid var(--elements-webflow-library-neutral--400);
    background-color: var(--elements-webflow-library-neutral--100);
    box-shadow: 0 2px 12px 0 var(--elements-webflow-library-general--shadow-01);
    color: var(--elements-webflow-library-neutral--800);
    text-align: center;
    border-radius: 40px;
    padding: 14px 22px;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-decoration: none;
    display: inline-block;
}

.brix---badge-wrapper-top-right {
    position: absolute;
    top: 20px;
    bottom: auto;
    left: auto;
    right: 20px;
}

.brix---product-card-image {
    width: auto;
    padding-bottom: 35px;
    position: static;
    bottom: 0;
}

.brix---product-card-image-inside-card {
    object-fit: fill;
    border-radius: 24px 24px 0 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
    position: relative;
    overflow: hidden;
    transform: translate(0);
}

.brix---product-card-wrapper-v1 {
    height: 100%;
    max-width: 1010px;
    border: 1px solid var(--elements-webflow-library-neutral--300);
    background-color: var(--elements-webflow-library-neutral--100);
    color: var(--elements-webflow-library-neutral--600);
    border-radius: 24px;
    margin-left: auto;
    text-decoration: none;
    transition: transform .3s;
    box-shadow: 0 2px 12px rgba(20, 20, 43, .08);
}

.brix---product-card-wrapper-v1:hover {
    transform: scale(.96);
}

.brix---grid-3-columns {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.brix---mg-bottom-40px {
    margin-bottom: 40px;
}

.brix---heading-h1-size {
    margin-top: 0;
    margin-bottom: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 54px;
    font-weight: 700;
    line-height: 66px;
}

.brix---subtitle {
    color: var(--elements-webflow-library-accent--primary-1);
    letter-spacing: .15em;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
}

.brix---text-center-2 {
    text-align: center;
}

.brix---inner-container-640px---center {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}

.brix---container-default-2 {
    max-width: 1218px;
    padding-left: 24px;
    padding-right: 24px;
}

.brix---section-2 {
    color: var(--elements-webflow-library-neutral--100);
    margin-top: -168px;
    padding-top: 176px;
    padding-bottom: 176px;
}

.brix---color-neutral-803 {
    color: var(--elements-webflow-library-neutral--800);
}

.brix---section-3 {
    color: var(--elements-webflow-library-neutral--100);
    padding-top: 176px;
    padding-bottom: 176px;
}

.f-margin-bottom-136 {
    margin-bottom: 8px;
}

.f-title-wrapper-center-2 {
    z-index: 5;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
}


.button-4 {
    background-color: #000;
    border-radius: 100px;
    font-size: 24px;
    position: relative;
    top: -397px;
    left: 356px;
}

.f-margin-bottom-137 {
    margin-bottom: 48px;
}

.f-container-regular-3 {
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.f-gallery-lightbox {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.f-gallery-image-l {
    border-radius: 8px;
    height: 440px;
    overflow: hidden;
}

.a-margin-bottom-48 {
    margin-bottom: 48px;
}

.a-h1-heading {
    color: #000;
    letter-spacing: -.04em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: min(15vw, 64px) !important;
    line-height: 1.2;
}

.a-paragraph-regular {
    letter-spacing: -.02em;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.8;
}

.a-header-button-wrapper {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
}

.a-detail-small.a-text-color-primary {
    color: var(--lynx-grey);
}

@media screen and (min-width: 1440px) {
    .paragraph-bigger.cc-bigger-white-light {
        color: rgba(46, 49, 33, .84);
    }

    .a-span-underline {
        color: var(--black);
    }

    .a-detail-small.a-text-color-primary {
        color: var(--lynx-grey);
    }

    .brix---subtitle-2 {
        color: var(--lynx-black);
    }

    .brix---container-default-4 {
        margin-top: -149px;
    }

    .intro-header.cc-subpage {
        height: 580px;
    }


    .button-4 {
        background-color: #000;
        border-radius: 100px;
        font-size: 24px;
        transition: all .125s;
        position: relative;
        left: 552px;
    }

    .button-4:hover {
        transform: scale(1.2);
    }

    .f-heading-detail-small-4 {
        color: var(--white);
    }

    .f-h3-heading-2 {
        color: var(--white);
        font-family: PT Serif, serif;
        font-weight: 700;
    }

    .button.cc-jumbo-button.cc-jumbo-white {
        background-color: #fffffd;
        margin-top: 101px;
        margin-bottom: -82px;
        padding-bottom: 16px;
    }

    .button.cc-jumbo-button.cc-jumbo-white:hover {
        color: #040404;
        background-color: #cecfc3;
    }

    .button.cc-contact-us:hover {
        background-color: #cecfc3;
    }

    .heading-jumbo {
        color: rgba(46, 49, 33, .84);
        margin-top: -30px;
    }

    .intro-header {
        background-image: url('../images/iStock-1442447917 (3) (1) (1).jpg');
        background-position: 50%;
        background-size: cover;
        position: static;
        top: auto;
    }

    .intro-content.cc-homepage {
        margin-bottom: 35px;
        padding-bottom: 0;
    }

    .team-member-image {
        width: 270px;
        height: 270px;
        max-height: none;
        transition: all .275s;
    }

    .team-member-image:hover {
        width: 270px;
        height: 270px;
        transform: scale(1.2);
    }

    .efi-plus-icon {
        width: 50px;
        height: 50px;
        font-size: 24px;
        line-height: 5;
    }

    .efi-paragraph {
        text-align: center;
    }

    .efi-h2 {
        font-weight: 600;
    }

    .efi-h2-5 {
        text-align: center;
    }

    .footer-subscribe {
        top: 136px;
    }

    .container-3 {
        margin-top: auto;
    }

    .image-2, .image-3 {
        transition: all .25s;
    }

    .f-account-link {
        margin-bottom: -3px;
    }

    .f-paragraph-small {
        margin-top: 35px;
    }

    .f-field-input {
        color: #000;
    }

    .f-account-content-wrapper {
        position: relative;
        top: -81px;
    }

    .f-image-cover {
        margin-left: 0;
        position: relative;
        left: auto;
    }

    .f-checkbox-label {
        font-size: 12px;
    }

    .f-field-input-2 {
        color: #000;
    }

    .f-paragraph-large {
        font-weight: 500;
    }

    .f-h1-heading {
        font-size: 58px;
    }

    .f-heading-detail-small {
        text-transform: none;
    }

    .f-container-regular {
        transition: all .3s;
    }

    .f-section-large {
        -webkit-text-fill-color: inherit;
        background-image: url('../images/iStock-1477970232 (2).jpg');
        background-position: 0 0;
        background-size: cover;
        background-clip: border-box;
        margin-top: 0;
        padding-top: 224px;
        padding-bottom: 88px;
        top: auto;
    }

    .f-section-large.druga {
        background-image: none;
        background-position: 0 0;
        background-size: auto;
    }

    .f-heading-detail-small-2 {
        text-transform: none;
        position: static;
    }

    .image-4 {
        width: 115px;
        height: 40px;
    }

    .image-5, .image-6 {
        width: 150px;
        height: 40px;
    }

    .f-header-button-wrapper {
        margin-left: 144px;
    }

    .f-paragraph-large-2 {
        text-align: center;
        font-weight: 600;
    }

    .f-h1-heading-2 {
        text-align: center;
        margin-left: 0;
    }

    .f-heading-detail-small-3 {
        margin-left: 27px;
    }

    .f-header-wrapper-left {
        align-items: stretch;
        margin-left: 242px;
        transition: all .275s;
        display: block;
    }

    .f-container-regular-2 {
        margin-left: 108px;
        transition: opacity .25s;
    }

    .f-section-large-2 {
        background-image: url('../images/iStock-1477970232 (2).jpg');
        background-position: 50%;
        background-size: cover;
        margin-top: 0;
        margin-bottom: 0;
        bottom: auto;
    }

    .select-field-2 {
        color: #000;
    }

    .text-field-2 {
        color: #080808;
        border-radius: 20px;
    }

    .select-field-3, .select-field-4, .select-field-5, .select-field-6 {
        color: #000;
    }

    .button-3 {
        color: #efefef;
        background-color: #000;
        border-radius: 100px;
        margin-left: 424px;
        transition: all .25s;
    }

    .button-3:hover {
        color: #000;
        background-color: #cecfc3;
    }

    .brix---product-card-image {
        padding-bottom: 0;
    }

    .brix---section-2 {
        margin-top: -153px;
    }
}

@media screen and (max-width: 991px) {
    .section.cc-cta {
        padding-left: 0;
        padding-right: 0;
    }

    .a-header-image-block {
        max-width: 100%;
        /*height: 500px;*/
        position: relative;
    }

    .a-header-button-wrapper {
        justify-content: center;
    }

    .button-4 {
        left: 228px;
    }

    .styleguide-block {
        text-align: center;
    }

    .paragraph-bigger.cc-bigger-white-light {
        opacity: 1;
        mix-blend-mode: normal;
        margin-left: 88px;
        font-weight: 500;
    }

    .button {
        justify-content: center;
    }

    .button.cc-jumbo-button.cc-jumbo-white {
        margin-top: 94px;
        margin-left: 96px;
        position: relative;
        top: -38px;
    }

    .button.cc-contact-us {
        display: none;
    }

    .heading-jumbo {
        margin-left: 64px;
        font-size: 56px;
        line-height: 70px;
    }

    .logo-link.w--current {
        flex: 1;
    }

    .menu-icon {
        display: block;
    }

    .menu {
        margin-left: 30px;
        position: static;
    }

    .navigation-wrap {
        background-color: #fff;
    }

    .navigation {
        padding: 25px 30px;
    }

    .navigation-item {
        text-align: center;
        padding: 15px 30px;
        transition: background-color .4s, opacity .4s, color .4s;
    }

    .navigation-item:hover {
        background-color: #f7f8f9;
    }

    .navigation-item:active {
        background-color: #eef0f3;
    }

    .menu-button {
        padding: 0;
    }

    .menu-button.w--open {
        background-color: rgba(0, 0, 0, 0);
    }

    .navigation-items {
        background-color: #fff;
    }

    .cta-text {
        width: auto;
    }

    .cta-wrap {
        width: auto;
        padding: 80px 50px 90px;
    }

    .intro-header {
        margin-left: -86px;
    }

    .our-services-grid {
        width: 80%;
        text-align: center;
        grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
        margin-left: auto;
        margin-right: auto;
    }

    .service-icon {
        display: inline-block;
    }

    .contact-form-grid {
        width: 80%;
        grid-row-gap: 50px;
        text-align: center;
        grid-template: "."
                   "."
                   / 1fr;
        margin-left: auto;
        margin-right: auto;
    }

    .get-in-touch-form {
        text-align: left;
    }

    .our-contacts {
        width: 80%;
        grid-template: "."
                   "."
                   "."
                   / 1fr;
        margin-left: auto;
        margin-right: auto;
    }

    .team-members {
        grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
    }

    .team-member-title-wrap {
        margin-bottom: 30px;
    }

    .project-details-grid {
        width: 80%;
        grid-row-gap: 50px;
        text-align: center;
        grid-template: "."
                   "."
                   / 1fr;
        margin-left: auto;
        margin-right: auto;
    }

    .blog-detail-header-wrap {
        width: 70%;
    }

    .detail-header-image {
        height: 460px;
    }

    .blog-item {
        width: 80%;
    }

    .blog-preview-image {
        height: 460px;
    }

    .blog-summary-wrap {
        width: 100%;
    }

    .about-grid {
        grid-row-gap: 50px;
        text-align: center;
        grid-template: "."
                   "."
                   / 1fr;
    }

    .about-grid.cc-about-2 {
        grid-template-columns: 1fr;
        grid-template-areas: "."
                         ".";
    }

    .intro-text {
        width: auto;
    }

    .collection-wrap {
        flex-flow: column;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }

    .blog-preview-wrap {
        width: 80%;
        text-align: center;
        padding-right: 15px;
    }

    .container-2 {
        max-width: 728px;
    }

    .team-grid {
        grid-column-gap: 40px;
    }

    .team-member-image {
        width: 190px;
        height: 190px;
        transition: opacity .4s;
    }

    .team-member-image:hover {
        transform: scale(1.2);
    }

    .efi-ft-09-accordion-wrap {
        padding-left: 20px;
        padding-right: 20px;
    }

    .efi-ft-09 {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .efi-tsm-02-icon {
        width: 5.2em;
    }

    .efi-tsm-02-div-3 {
        width: 100%;
        padding-left: 0;
    }

    .efi-tsm-02-div-2 {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .efi-tsm-02-div-1 {
        width: 100%;
        padding-right: 0;
    }

    .efi-tsm-02-testimonials {
        flex-direction: column;
    }

    .efi-tsm-02-grid {
        grid-template-columns: 1fr;
    }

    .efi-tsm-02-div-4 {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .efi-tsm-02-div-5 {
        width: 100%;
        padding-right: 0;
    }

    .efi-tsm-02-div-6 {
        width: 100%;
        padding-left: 0;
    }

    .efi-tsm-02-div-7 {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .efi-tsm-02-div-8 {
        width: 100%;
        padding-right: 0;
    }

    .efi-tsm-01-grid {
        grid-template-columns: 1fr;
    }

    .container-3 {
        max-width: 728px;
    }

    .footer-form-two {
        padding: 40px;
    }

    .footer-form-title {
        margin-right: 15px;
    }

    .footer-form-input {
        width: 400px;
        padding-right: 180px;
    }

    .footer-wrapper-three {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-social-block-three {
        margin-top: 40px;
    }

    .f-account-container-r {
        width: 60%;
    }

    .f-account-image-wrapper {
        width: 40%;
    }

    .f-account-section {
        height: auto;
    }

    .f-account-container-r-2, .f-account-container-r-3 {
        width: 60%;
    }

    .f-account-image-wrapper-2 {
        width: 40%;
    }

    .f-account-section-2 {
        height: auto;
    }

    .f-h1-heading {
        position: relative;
        right: auto;
    }

    .f-heading-detail-small {
        position: relative;
        left: 8px;
    }

    .f-container-regular {
        left: 76px;
    }

    .f-section-large {
        margin-left: -186px;
    }

    .f-grid-three-column {
        grid-template-columns: 1fr 1fr;
    }

    .lynx-heading-features-large {
        font-size: 32px;
    }

    .lynx-block-features-large {
        padding: 45px 30px 25px;
    }

    .lynx-grid-2-columns {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .brix---mg-bottom-32px {
        margin-bottom: 24px;
    }

    .brix---heading-h3-size {
        font-size: 22px;
        line-height: 32px;
    }

    .brix---card-link-img-bg {
        padding-bottom: 40px;
        padding-left: 36px;
        padding-right: 36px;
    }

    .brix---heading-h2-size {
        font-size: 30px;
        line-height: 46px;
    }

    .brix---mg-bottom-48px {
        margin-bottom: 40px;
    }

    .brix---section {
        padding-top: 118px;
        padding-bottom: 118px;
    }

    .f-header-button-wrapper {
        margin-left: 167px;
    }

    .f-container-regular-2 {
        margin-left: 40px;
    }

    .f-section-large-2 {
        background-position: 50%;
    }

    .button-3 {
        margin-left: 316px;
    }

    .brix---btn-primary {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .brix---heading-h4-size {
        font-size: 22px;
        line-height: 32px;
    }

    .brix---product-card-image {
        width: 100%;
    }

    .brix---grid-3-columns {
        grid-template-columns: 1fr 1fr;
    }

    .brix---heading-h1-size {
        font-size: 48px;
        line-height: 60px;
    }

    .brix---section-2, .brix---section-3 {
        padding-top: 118px;
        padding-bottom: 118px;
    }
}

@media screen and (max-width: 767px) {
    .section {
        margin-left: 15px;
        margin-right: 15px;
    }

    .a-h1-heading {
        /*font-size: 48px;*/
    }

    .brix---subtitle-2 {
        font-size: 17px;
        line-height: 19px;
    }

    .intro-header.cc-subpage {
        background-position: 50%;
        margin-left: -16px;
    }

    .button-4 {
        left: 137px;
    }

    .f-gallery-image-l {
        height: 420px;
    }

    .f-h3-heading-2 {
        font-size: 40px;
    }

    .section.cc-cta {
        margin-left: -3px;
        margin-right: -19px;
        padding: 15px;
    }

    .section.cc-home-wrap, .section.cc-store-home-wrap {
        margin-left: 15px;
        margin-right: 15px;
    }

    .container {
        text-align: center;
    }

    .paragraph-bigger {
        font-size: 16px;
        line-height: 28px;
    }

    .paragraph-bigger.cc-bigger-white-light {
        margin-left: 171px;
    }

    .button.cc-jumbo-button.cc-jumbo-white {
        top: -32px;
        left: 50px;
    }

    .rich-text {
        width: 90%;
        max-width: 470px;
        text-align: left;
    }

    .heading-jumbo {
        margin-left: 132px;
        font-size: 50px;
        line-height: 64px;
    }

    .heading-jumbo-small {
        font-size: 30px;
        line-height: 52px;
    }

    .logo-link {
        padding-left: 0;
    }

    .navigation {
        padding: 20px 30px;
    }

    .cta-wrap {
        padding-left: 30px;
        padding-right: 30px;
    }

    .intro-header {
        margin-left: -217px;
        margin-right: -16px;
    }

    .intro-content {
        width: 80%;
    }

    .intro-content.cc-homepage {
        margin-right: -49px;
    }

    .our-services-grid {
        grid-row-gap: 60px;
        grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
    }

    .status-message.cc-no-data {
        width: 80%;
        max-width: 470px;
    }

    .contact-form-wrap {
        padding: 30px;
    }

    .text-field.cc-contact-field, .text-field.cc-textarea {
        text-align: left;
    }

    .team-pic {
        height: 300px;
        margin-bottom: 30px;
    }

    .team-member-name {
        font-size: 16px;
        line-height: 28px;
    }

    .project-name-wrap {
        margin-bottom: 30px;
    }

    .project-name-link {
        font-size: 16px;
        line-height: 28px;
    }

    .detail-image {
        margin-bottom: 15px;
    }

    .blog-detail-header-wrap {
        width: 90%;
        max-width: 470px;
        text-align: left;
    }

    .blog-item {
        width: 90%;
        max-width: 470px;
    }

    .blog-summary-wrap {
        text-align: center;
    }

    .utility-page-wrap {
        padding: 15px;
    }

    ._404-wrap {
        padding: 30px;
    }

    .team-circles {
        padding: 60px 15px;
    }

    .team-grid {
        grid-template-columns: 1fr 1fr;
    }

    .efi-ft-09-grid {
        grid-template-columns: 1fr;
    }

    .efi-tsm-01-logos-grid {
        grid-template-columns: 1fr 1fr;
    }

    .footer-subscribe {
        padding-left: 15px;
        padding-right: 15px;
    }

    .footer-form-container-two {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-form-title {
        margin-right: 0;
    }

    .footer-form-block-two {
        width: 100%;
        margin-top: 20px;
    }

    .footer-form-input {
        width: 100%;
    }

    .footer-block-three {
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-link-three {
        margin-bottom: 10px;
    }

    .footer-social-block-three {
        margin-top: 10px;
    }

    .footer-bottom {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-copyright {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-legal-block {
        margin-top: 15px;
        margin-left: -24px;
    }

    .footer-legal-link {
        font-size: 14px;
        line-height: 20px;
    }

    .f-account-container-r {
        width: 100%;
        justify-content: center;
    }

    .f-account-image-wrapper {
        width: 100%;
        height: 600px;
    }

    .f-account-section {
        flex-direction: column;
    }

    .f-account-container-r-2, .f-account-container-r-3 {
        width: 100%;
        justify-content: center;
    }

    .f-account-image-wrapper-2 {
        width: 100%;
        height: 600px;
    }

    .f-account-section-2 {
        flex-direction: column;
    }

    .f-paragraph-large {
        margin-left: 37px;
        font-size: 18px;
    }

    .f-h1-heading {
        margin-top: 0;
        font-size: 48px;
        left: 14px;
    }

    .f-section-large {
        padding-top: 72px;
        padding-bottom: 72px;
    }

    .f-grid-three-column {
        grid-template-columns: 1fr;
    }

    .f-h3-heading {
        font-size: 40px;
    }

    .lynx-heading-features-large {
        font-size: 26px;
    }

    .lynx-grid-2-columns {
        grid-template-columns: 1fr;
    }

    .brix---link-icon-right {
        width: 20px;
        margin-left: 8px;
    }

    .brix---text-200-bold {
        font-size: 16px;
        line-height: 18px;
    }

    .brix---grid-2-columns---gap-row-72px {
        grid-row-gap: 48px;
        grid-template-columns: 1fr;
    }

    .brix---heading-h2-size {
        font-size: 28px;
        line-height: 40px;
    }

    .brix---mg-bottom-48px {
        margin-bottom: 32px;
    }

    .brix---section {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .f-h1-heading-2 {
        margin-top: 0;
        font-size: 48px;
    }

    .f-header-wrapper-left {
        margin-left: -95px;
    }

    .f-section-large-2 {
        padding-top: 72px;
        padding-bottom: 72px;
    }

    .lynx-grid-footer {
        grid-row-gap: 40px;
        grid-template-columns: 1fr;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .button-3 {
        margin-left: 234px;
    }

    .brix---btn-primary {
        padding: 20px 32px;
    }

    .brix---heading-h4-size {
        font-size: 20px;
        line-height: 26px;
    }

    .brix---mg-bottom-16px {
        margin-bottom: 8px;
    }

    .brix---badge-secondary-small-bold {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .brix---product-card-wrapper-v1 {
        width: 100%;
    }

    .brix---grid-3-columns {
        grid-template-columns: 1fr;
    }

    .brix---mg-bottom-40px {
        margin-bottom: 24px;
    }

    .brix---heading-h1-size {
        margin-bottom: 12px;
        font-size: 38px;
        line-height: 46px;
    }

    .brix---subtitle {
        font-size: 17px;
        line-height: 19px;
    }

    .brix---section-2, .brix---section-3 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.a-span-underline {
    border-bottom: 6px solid var(--alley-component-library--blue--900);
    color: var(--black);
}


@media screen and (max-width: 479px) {
    h1 {
        font-size: 36px;
        line-height: 52px;
    }


    .lynx-heading {
        font-size: 29px;
    }

    .lynx-heading-features-small-2 {
        font-size: 18px;
    }

    .lynx-grid-features-4-columns {
        grid-row-gap: 40px;
        grid-template-columns: 1fr;
    }

    .lynx-heading-features {
        font-size: 19px;
    }


    .a-header-image-block {
        /*height: 320px;*/
    }

    .a-header-button-wrapper {
        flex-direction: column;
    }

    .a-h1-heading {
        padding-left: 0;
        /*font-size: 46px;*/
    }

    .brix---text-200-bold-3, .brix---text-200 {
        font-size: 15px;
        line-height: 16px;
    }

    .brix---heading-h3-size-3 {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 30px;
    }

    .brix---square-icon {
        border-radius: 14px;
        max-width: 56px;
    }

    .brix---mg-bottom-24px-2 {
        margin-bottom: 16px;
    }

    .brix---3-columns-1-col-tablet {
        grid-template-columns: 1fr;
    }

    .brix---mg-bottom-160px {
        margin-bottom: 60px;
    }

    .brix---heading-h1-size-2 {
        margin-bottom: 10px;
        font-size: 30px;
        line-height: 37px;
    }

    .brix---subtitle-2 {
        font-size: 14px;
        line-height: 18px;
    }

    .brix---container-default-4 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .intro-header.cc-subpage {
        background-position: 50%;
        margin-left: -16px;
    }

    .button-4 {
        top: -331px;
        left: 20px;
    }

    .button-5 {
        left: 21px;
    }

    .f-gallery-image-l {
        height: 320px;
    }

    .paragraph-bigger.cc-bigger-white-light {
        margin-left: 251px;
        padding-left: 69px;
    }

    .button.cc-jumbo-button.cc-jumbo-white {
        margin-top: 54px;
        margin-bottom: 18px;
        margin-left: 206px;
        padding-top: 16px;
    }

    .rich-text {
        width: 100%;
        max-width: none;
    }

    .heading-jumbo {
        margin-top: -274px;
        margin-left: 251px;
        padding-left: 59px;
        font-size: 36px;
        line-height: 48px;
    }

    .menu {
        margin-left: 15px;
    }

    .navigation {
        padding-left: 20px;
        padding-right: 20px;
    }

    .menu-button, .menu-button.w--open {
        flex: none;
    }

    .cta-wrap {
        padding-left: 15px;
        padding-right: 15px;
    }

    .intro-header {
        margin-left: -326px;
        padding-left: 0;
    }

    .status-message.cc-no-data, .contact-form-grid {
        width: 100%;
    }

    .our-contacts {
        width: 90%;
    }

    .team-members {
        grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
    }

    .project-details-grid {
        width: 100%;
    }

    .blog-detail-header-wrap, .blog-item {
        width: 100%;
        max-width: none;
    }

    .intro-text {
        text-align: center;
        flex-direction: row;
        align-items: center;
        display: block;
    }

    .container-2 {
        max-width: none;
    }

    .centered-heading {
        margin-bottom: 24px;
    }

    .team-grid {
        grid-template-columns: 1fr;
    }

    .efi-tsm-02-testimonial-top {
        text-align: left;
        justify-content: center;
    }

    .container-3 {
        max-width: none;
    }

    .footer-form-two {
        padding: 20px;
    }

    .footer-form-block-two {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-form-input {
        margin-bottom: 10px;
        padding-right: 20px;
    }

    .button-primary.footer-form-button {
        position: relative;
        right: 0;
    }

    .footer-bottom {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .button-2 {
        margin-top: -65px;
        margin-bottom: -8px;
        padding-top: 2px;
    }

    .f-field-label {
        color: var(--flowui-component-library-gray-900);
        font-size: 16px;
        font-weight: 600;
    }

    .f-account-container-r {
        margin-top: -200px;
        margin-bottom: 249px;
        top: -450px;
    }

    .f-image-cover {
        margin-top: 0;
    }

    .f-field-label-2 {
        font-size: 16px;
        font-weight: 600;
    }

    .f-account-container-r-3 {
        top: -657px;
    }

    .f-account-image-wrapper-2 {
        height: 600px;
        margin-bottom: 0;
        padding-bottom: 0;
        position: relative;
        bottom: auto;
    }

    .f-header-button-middle {
        justify-content: center;
    }

    .f-h1-heading {
        margin-left: 0;
        font-size: 48px;
        left: auto;
    }

    .f-heading-detail-small {
        font-size: 12px;
        left: auto;
    }

    .f-header-title-wrapper-center {
        width: 100%;
        object-fit: fill;
        flex-direction: column;
        margin-right: -66px;
        display: flex;
    }

    .f-section-large {
        margin-left: -297px;
    }

    .lynx-image-features {
        min-height: 200px;
    }

    .lynx-heading-features-large {
        font-size: 24px;
    }

    .brix---link-icon-right {
        width: 18px;
    }

    .brix---text-200-bold {
        font-size: 15px;
        line-height: 16px;
    }

    .brix---paragraph-default {
        font-size: 17px;
        line-height: 28px;
    }

    .brix---heading-h3-size {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 30px;
    }

    .brix---mg-bottom-8px {
        margin-bottom: 6px;
    }

    .brix---card-link-img-bg {
        min-height: 440px;
        padding-left: 28px;
        padding-right: 28px;
    }

    .brix---heading-h2-size {
        font-size: 24px;
        line-height: 34px;
    }

    .brix---mg-bottom-48px {
        margin-bottom: 32px;
    }

    .brix---container-default {
        padding-left: 20px;
        padding-right: 20px;
    }

    .brix---section {
        opacity: .97;
        background-color: #fff;
    }

    .f-button-secondary-2 {
        margin-left: -11px;
    }

    .f-header-button-wrapper {
        margin-left: 135px;
    }

    .f-paragraph-large-2 {
        font-size: 12px;
        font-weight: 500;
    }

    .f-h1-heading-2 {
        padding-left: 29px;
        padding-right: 24px;
        font-size: 37px;
    }

    .f-heading-detail-small-3 {
        margin-left: 14px;
        font-size: 12px;
    }

    .select-field-2 {
        color: var(--black);
    }

    .button-3 {
        margin-left: 120px;
    }

    .brix---btn-primary {
        width: 100%;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 17px;
        line-height: 19px;
    }

    .brix---paragraph-default-2 {
        font-size: 17px;
        line-height: 28px;
    }

    .brix---mg-bottom-24px {
        margin-bottom: 16px;
    }

    .brix---heading-h4-size {
        font-size: 18px;
        line-height: 24px;
    }

    .brix---mg-bottom-16px {
        margin-bottom: 15px;
    }

    .brix---product-card-content {
        padding: 21px 22px 32px;
    }

    .brix---badge-secondary-small-bold {
        padding: 10px 16px;
        font-size: 14px;
        line-height: 16px;
    }

    .brix---badge-wrapper-top-right {
        top: 14px;
        right: 14px;
    }

    .brix---grid-3-columns {
        grid-template-columns: 1fr;
    }

    .brix---heading-h1-size {
        margin-top: 66px;
        margin-bottom: 10px;
        font-size: 30px;
        line-height: 37px;
        display: inline-block;
    }

    .brix---subtitle {
        font-size: 14px;
        line-height: 18px;
    }

    .brix---container-default-2 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

#w-node-_86e64837-0616-515b-4568-76c147234d34-408e5274 {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-_86e64837-0616-515b-4568-76c147234d3f-408e5274, #w-node-_86e64837-0616-515b-4568-76c147234d41-408e5274 {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-_86e64837-0616-515b-4568-76c147234d4c-408e5274 {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500bf-408e5274, #w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500ca-408e5274, #w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500d4-408e5274, #w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500de-408e5274, #w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500e8-408e5274, #w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500f2-408e5274, #w-node-_41305b7f-56a4-1376-3bce-e6505bb59672-408e5274, #w-node-_41305b7f-56a4-1376-3bce-e6505bb59675-408e5274, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600c7-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600c9-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600cb-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600cd-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600cf-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d3-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d4-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d5-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d6-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d7-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d8-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d9-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600da-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600db-c72600c4 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118e6-408e5277 {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118ec-408e5277 {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118f2-408e5277 {
    grid-area: 1 / 3 / 2 / 4;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118f8-408e5277 {
    grid-area: 2 / 3 / 3 / 4;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118fe-408e5277 {
    grid-area: 2 / 1 / 3 / 2;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b11905-408e5277 {
    grid-area: 2 / 2 / 3 / 3;
}

#w-node-_3f61509a-fac7-b0d5-3763-509be705858e-408e5279 {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-_3f61509a-fac7-b0d5-3763-509be70585a6-408e5279 {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb08-408e5279 {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb15-408e5279 {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb22-408e5279 {
    grid-area: 1 / 3 / 2 / 4;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-408e527b {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-408e527b {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-408e527c {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-408e527c {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-408e527d {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-408e527d {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cdc-408e527e {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38ce3-408e527e {
    grid-area: 2 / 1 / 3 / 2;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cea-408e527e {
    grid-area: 3 / 1 / 4 / 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9803c-408e5280 {
    grid-area: 1 / 1 / 2 / 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98043-408e5280 {
    grid-area: 1 / 2 / 2 / 3;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-408e5280 {
    grid-area: 1 / 3 / 2 / 4;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98051-408e5280 {
    grid-area: 2 / 1 / 3 / 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98058-408e5280 {
    grid-area: 2 / 2 / 3 / 3;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-408e5280 {
    grid-area: 2 / 3 / 3 / 4;
}

#w-node-_5b43e274-aea0-906a-5397-dafda7657e42-a7657e35, #w-node-_5b43e274-aea0-906a-5397-dafda7657e75-a7657e35 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (min-width: 1440px) {
    #w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500ca-408e5274, #w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500d4-408e5274, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d1-c72600c4, #w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d2-c72600c4 {
        grid-area: span 1 / span 1 / span 1 / span 1;
    }

    .lynx-button:hover {
        background-color: var(--lynx-grey);
        color: var(--black);
    }

    .lynx-icon-features {
        width: 82px;
        height: 52px;
    }

    .a-header-grid-image {
        margin-left: 4px;
        position: relative;
        left: auto;
    }

    .a-header-logo-block {
        width: auto;
    }

    .a-paragraph-small {
        width: auto;
        font-size: 14px;
        font-weight: 400;
    }

    .a-button-video {
        color: var(--flowui-component-library-success-lighter);
        background-color: #000;
    }

    .a-icon-small {
        color: var(--black);
    }

    .a-button-primary {
        background-color: var(--black);
    }

    .a-button-primary:hover {
        background-color: var(--flowui-component-library-gray-100);
        color: #000;
    }

    .f-section-large-3 {
        background-image: url('../images/iStock-1091707454.webp');
        background-position: 50%;
        background-size: cover;
    }

    #w-node-f13b8209-d387-c54f-6ac0-793624eb80a3-d632206e {
        grid-area: span 1 / span 1 / span 1 / span 1;
        align-self: auto;
        justify-self: auto;
    }
}

@media screen and (max-width: 991px) {
    #w-node-_86e64837-0616-515b-4568-76c147234d34-408e5274 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .lynx-heading {
        font-size: 38px;
    }

    .lynx-grid-image-right {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .lynx-heading-features-small-2 {
        font-size: 19px;
    }

    .lynx-grid-features-4-columns {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .lynx-heading-features {
        font-size: 22px;
    }

    .lynx-grid-features-2 {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }


    .a-button-primary {
        display: flex;
    }

    #w-node-_86e64837-0616-515b-4568-76c147234d3f-408e5274 {
        grid-area: 1 / 1 / 2 / 2;
    }

    #w-node-_86e64837-0616-515b-4568-76c147234d41-408e5274 {
        grid-area: 2 / 1 / 3 / 2;
    }

    #w-node-_86e64837-0616-515b-4568-76c147234d4c-408e5274 {
        grid-area: 1 / 1 / 2 / 2;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b118f2-408e5277 {
        grid-area: 2 / 1 / 3 / 2;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b118f8-408e5277 {
        grid-area: 3 / 2 / 4 / 3;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b118fe-408e5277 {
        grid-area: 2 / 2 / 3 / 3;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b11905-408e5277 {
        grid-area: 3 / 1 / 4 / 2;
    }

    #w-node-_3f61509a-fac7-b0d5-3763-509be70585a6-408e5279, #w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb15-408e5279 {
        grid-area: 2 / 1 / 3 / 2;
    }

    #w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb22-408e5279 {
        grid-area: 3 / 1 / 4 / 2;
    }

    #w-node-_421df62a-c217-12af-eee8-27f88a387928-408e527b, #w-node-_421df62a-c217-12af-eee8-27f88a387928-408e527c, #w-node-_421df62a-c217-12af-eee8-27f88a387928-408e527d, #w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-408e5280 {
        grid-area: 2 / 1 / 3 / 2;
    }

    #w-node-c28cc763-f708-1e61-f196-6bd9fce98051-408e5280 {
        grid-area: 2 / 2 / 3 / 3;
    }

    #w-node-c28cc763-f708-1e61-f196-6bd9fce98058-408e5280 {
        grid-area: 3 / 1 / 4 / 2;
    }

    #w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-408e5280 {
        grid-area: 3 / 2 / 4 / 3;
    }

    #w-node-_697f890c-704d-75ec-90e6-e16ba52176a2-a52176a0 {
        order: -9999;
    }
}

@media screen and (max-width: 767px) {
    #w-node-ca1212d8-4247-8b87-bc90-543393b118ec-408e5277 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .lynx-image-absolute {
        left: -2%;
    }

    .lynx-heading {
        max-width: 540px;
        font-size: 34px;
    }

    .lynx-block-left {
        padding-left: 5%;
        padding-right: 5%;
    }

    .lynx-grid-image-right {
        grid-row-gap: 60px;
        grid-template-columns: 1fr;
    }

    .lynx-grid-features-4-columns {
        grid-template-columns: 1fr 1fr;
    }

    .lynx-heading-features {
        font-size: 20px;
    }

    .lynx-grid-features-2 {
        grid-row-gap: 40px;
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .f-section-large-3 {
        padding-top: 72px;
        padding-bottom: 72px;
    }

    .button-5 {
        left: -116px;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b118f2-408e5277 {
        grid-area: 3 / 1 / 4 / 2;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b118f8-408e5277 {
        grid-area: 6 / 1 / 7 / 2;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b118fe-408e5277 {
        grid-area: 4 / 1 / 5 / 2;
    }

    #w-node-ca1212d8-4247-8b87-bc90-543393b11905-408e5277 {
        grid-area: 5 / 1 / 6 / 2;
    }

    .menu-toggle {
        display: block;
    }

    .nav-menu {
        display: none;
        flex-direction: column;
        gap: 15px;
        position: absolute;
        top: 60px;
        right: 20px;
        background-color: #fff;
        padding: 10px 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: calc(100% - 40px);
        max-width: 300px;
    }

    .nav-menu.open {
        display: flex;
    }

    .nav-link {
        font-size: 20px;
    }

    .button.login-button {
        font-size: 20px;
    }
}


.a-header-grid-column {
    grid-row-gap: 24px;
    flex-direction: column;
    display: flex;
}

.lynx-content-3 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    display: flex;
}


.lynx-grid-image-right {
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1.2fr 1fr;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 479px) {
    #w-node-c28cc763-f708-1e61-f196-6bd9fce98043-408e5280 {
        grid-area: 2 / 1 / 3 / 2;
    }


    .a-header-grid-column {
        grid-row-gap: 12px;
    }


    .a-header-image-grid {
        grid-column-gap: 12px;
    }

    .a-button-primary {
        width: 100%;
    }

    .brix---paragraph-default-4 {
        font-size: 17px;
        line-height: 28px;
    }

    .brix---section-position-relative {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    .f-section-large-3 {
        background-position: 50%;
        background-repeat: repeat;
        background-size: cover;
    }

    #w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-408e5280 {
        grid-area: 3 / 1 / 4 / 2;
    }

    #w-node-c28cc763-f708-1e61-f196-6bd9fce98051-408e5280 {
        grid-area: 4 / 1 / 5 / 2;
    }

    #w-node-c28cc763-f708-1e61-f196-6bd9fce98058-408e5280 {
        grid-area: 5 / 1 / 6 / 2;
    }

    #w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-408e5280 {
        grid-area: 6 / 1 / 7 / 2;
    }
}

.lynx-block-features-green {
    background-color: rgba(50, 183, 122, .1);
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 22px;
}

.lynx-grid-features {
    width: 100%;
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
    margin-top: 14px;
}

.f-section-large-3 {
    background-image: url('../images/iStock-1091707454.webp');
    background-position: 50%;
    background-size: auto;
    padding: 92px 5%;
    position: relative;
}


@media (max-width: 767px) {
    .button.cc-jumbo-button.cc-jumbo-white {
        margin-top: 20px; /* Adjust the margin to center the button */
        margin-bottom: 10px; /* Adjust the margin as needed */
        padding: 12px 20px; /* Adjust padding for better fit on small screens */
    }

    .image-2, .image-3 {
        width: 100%; /* Ensure the image takes the full width */
        height: auto; /* Maintain aspect ratio */
    }

    .w-layout-grid.about-grid, .w-layout-grid.about-grid.cc-about-2 {
        grid-template-columns: 1fr; /* Stack the grid items vertically */
    }

    .intro-content.cc-homepage {
        text-align: center; /* Center align text on smaller screens */
    }
}

html {
    scroll-behavior: smooth;
}


.brix---section-position-relative {
    padding-top: 176px;
    padding-bottom: 176px;
    position: relative;
    overflow: hidden;
}

.brix---container-default-4 {
    max-width: 1218px;
    padding-left: 24px;
    padding-right: 24px;
}

.brix---inner-container-700px-center {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.brix---text-center-4 {
    text-align: center;
}

.brix---subtitle-2 {
    color: var(--elements-webflow-library-accent--primary-1);
    letter-spacing: .15em;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
}

.brix---color-neutral-812 {
    color: var(--elements-webflow-library-neutral--800);
}

.brix---heading-h1-size-2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 54px;
    font-weight: 700;
    line-height: 66px;
}

.brix---mg-bottom-40px-2 {
    margin-bottom: 40px;
}


.brix---color-neutral-813 {
    color: var(--elements-webflow-library-neutral--600);
}

.brix---paragraph-default-4 {
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 18px;
    line-height: 30px;
}

.brix---mg-bottom-160px {
    margin-bottom: 160px;
}

.brix---3-columns-1-col-tablet {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}


.brix---card-link-center {
    border: 1px solid var(--elements-webflow-library-neutral--300);
    background-color: var(--elements-webflow-library-neutral--100);
    box-shadow: 0 2px 7px 0 var(--elements-webflow-library-general--shadow-01);
    color: var(--elements-webflow-library-neutral--600);
    text-align: center;
    transform-style: preserve-3d;
    border-radius: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 24px;
    text-decoration: none;
    transition: transform .3s, color .3s;
    display: flex;
}

.brix---card-link-center:hover {
    transform: scale3d(.94, .94, 1.01);
}

.brix---mg-bottom-24px-2 {
    margin-bottom: 24px;
}

.brix---square-icon {
    border-radius: 16px;
}

.brix---heading-h3-size-3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
}

.brix---link-wrapper-color-primary {
    color: var(--elements-webflow-library-accent--primary-1);
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
    transition: color .3s;
    display: flex;
}

.brix---link-wrapper-color-primary:hover {
    color: var(--elements-webflow-library-secondary--color-1);
}

.brix---text-200-bold-3 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
}

.pdf-container {
    width: 100%;
    /*max-width: 600px;*/
    margin: auto;
    padding: 10px;
}

.pdf-iframe {
    width: 100%;
    height: 600px;
    border: none;
    object-fit: cover;
}

.pdf-download-link {
    display: block;
    margin-top: 10px;
    text-align: center;
    color: #007bff;
    text-decoration: none;
}

.pdf-download-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .pdf-iframe {
        height: 400px;
    }
}

.phone-number-section {
    margin-top: 20px;
    text-align: center;
}

.phone-number-text {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.phone-number-input {
    font-size: 16px;
    padding: 10px;
    width: 300px;
    margin-bottom: 20px;
    text-align: center;
}

.a-header-section {
    padding: 120px 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 1000px) {
    .a-header-section {
        flex-direction: column;
    }

    .a-header-section .text-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.a-container-regular {
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}


.a-button-primary {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    background-color: var(--black);
    color: #fff;
    text-align: center;
    letter-spacing: -.02em;
    border-radius: 99px;
    flex-direction: row;
    flex: none;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    padding: 12px 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    transition: all .5s cubic-bezier(.6, .6, 0, 1);
}

.a-button-primary:hover {
    background-color: var(--elements-webflow-library-neutral--400);
    color: #000;
}

.a-button-primary:active {
    background-color: var(--alley-component-library--blue--700);
}


.a-button-video {
    grid-column-gap: 12px;
    color: var(--white);
    text-align: center;
    letter-spacing: -.02em;
    background-color: #000;
    border-radius: 99px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    min-height: 48px;
    padding: 12px 24px 12px 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    transition: all .5s cubic-bezier(.6, .6, 0, 1);
    display: flex;
}

.a-button-video:hover {
    background-color: var(--alley-component-library--neutral--off-white);
}

.a-button-video:active {
    background-color: var(--alley-component-library--blue--100);
}

.a-header-play {
    background-color: var(--alley-component-library--blue--100);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    display: flex;
}

.a-icon-small {
    color: var(--black);
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    display: flex;
}

.a-caption {
    letter-spacing: -.01em;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
}

.a-header-logo-wrapper {
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    color: var(--alley-component-library--gray--500);
    justify-content: space-between;
    align-items: center;
    margin-top: 140px;
    display: flex;
}

.a-paragraph-small {
    letter-spacing: -.02em;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.8;
}


.a-header-image-block {
    width: 800px;
}

.a-header-logo-block {
    grid-column-gap: 44px;
    grid-row-gap: 32px;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
}


.a-header-image-grid {
    grid-column-gap: 24px;
    grid-template-rows: auto;
    align-items: start;
    width: 100%;
    margin-top: 75px;
    padding-right: 5%;
    position: absolute;
    inset: 0% auto auto 0%;
}

.lynx-image {
    object-fit: cover;
    border-radius: 30px;
    min-height: 500px;
    box-shadow: 0 60px 60px -15px #1f20221a;
}

.lynx-image-absolute {
    z-index: 50;
    object-fit: cover;
    border-radius: 30px;
    max-width: 56%;
    position: absolute;
    inset: auto auto -6% -12%;
    box-shadow: 0 60px 60px -15px #1f20221a;
}

.lynx-block-image {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: relative;
}

.lynx-button {
    background-color: var(--lynx-black);
    color: var(--lynx-white);
    letter-spacing: 1.3px;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 22px 36px;
    font-family: Varela, sans-serif;
    font-size: 13px;
    line-height: 140%;
    text-decoration: none;
    transition: background-color .2s;
}

.lynx-button:hover {
    background-color: var(--lynx-medium-purple);
}

.lynx-paragraph-3 {
    color: var(--lynx-grey);
    max-width: 820px;
    margin-bottom: 36px;
    font-family: Varela, sans-serif;
    font-size: 18px;
    line-height: 165%;
}

.lynx-heading {
    color: var(--lynx-black);
    max-width: 740px;
    margin-top: 0;
    margin-bottom: 36px;
    font-family: PT Serif, serif;
    font-size: 46px;
    font-weight: 400;
    line-height: 120%;
}

.lynx-subtitle {
    color: var(--lynx-black);
    letter-spacing: 1.3px;
    text-transform: uppercase;
    font-family: Varela, sans-serif;
    font-size: 12px;
    line-height: 140%;
}

.lynx-block-left {
    padding-right: 15%;
}

.lynx-grid-image-right {
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1.2fr 1fr;
    align-items: center;
    width: 100%;
}

.lynx-content-3 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.lynx-paragraph-features-2 {
    color: var(--lynx-grey);
    max-width: 294px;
    margin-bottom: 0;
    font-family: Varela, sans-serif;
    font-size: 16px;
    line-height: 165%;
}

.lynx-heading-features-small-2 {
    color: var(--lynx-black);
    margin-top: 0;
    margin-bottom: 20px;
    font-family: PT Serif, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 128%;
}

.lynx-icon-features-small-2 {
    width: 28px;
    height: 28px;
    display: block;
}

.lynx-block-features-yellow {
    background-color: #acb7321a;
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 22px;
}

.lynx-features {
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.lynx-block-features-green-2 {
    background-color: #32b77a1a;
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 22px;
}

.lynx-block-features-purple-2 {
    background-color: #8979ec1a;
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 22px;
}

.lynx-block-features-pink-2 {
    background-color: #fd4b8b1a;
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 22px;
}

.lynx-grid-features-4-columns {
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: start;
    width: 100%;
    margin-top: 14px;
}

.lynx-block-center {
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.lynx-heading-features {
    color: var(--lynx-black);
    margin-top: 0;
    margin-bottom: 20px;
    font-family: PT Serif, serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 128%;
}

.lynx-icon-features {
    width: 32px;
    height: 32px;
    display: block;
}

.lynx-block-features {
    background-color: var(--lynx-white);
    border-radius: 30px;
    margin-bottom: 26px;
    padding: 28px;
    box-shadow: 0 40px 40px -10px #1f202214;
}

.lynx-grid-features-2 {
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
    width: 100%;
    margin-top: 14px;
}

body .a-header-section .text-block {
    margin-bottom: 20px;
}

body .a-header-section h2 {
    font-size: min(10vw, 48px) !important;
}
body .deals-container{
    margin:20px auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
body .deals-container .lynx-features{
    width: 25%;
}
body .deals-container p{
    text-align: justify;
}
body .all-insurances-container{
    margin:20px auto;
    width: 80%;
    display: flex;
    justify-content: center;
}
body .all-insurances-container .lynx-features{
    width: 33%;
}
@media (max-width: 1550px) {
    body .deals-container .lynx-features{
        margin-top: 20px;
        width: 50%;
    }
}
@media (max-width: 768px) {
    body .deals-container .lynx-features{
        width: 80%;
        margin:20px auto;
    }
    body .all-insurances-container .lynx-features{
        width: 100%;
    }
    body .all-insurances-container{
        flex-wrap: wrap;
    }
}

