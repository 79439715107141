@media (max-width: 600px) {
    .intro-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .intro-text, .button.cc-jumbo-button2 {
        width: 100%;
        text-align: center;
    }

    .button.cc-jumbo-button2 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
}
