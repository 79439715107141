body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scroll */
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.learn-more-header {
    max-width: 100% !important;
    margin: unset !important;
    display: flex;
    justify-content: center;
}

.learn-more-text-container {
    margin: unset !important;
}

.propaganda-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.propaganda-container div {
    width: 29%;
    margin: 10px;
}
.kasko-title-container{
    max-width: 800px;
    margin:auto;
}
.kasko-card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:32px 32px 20px 32px!important;
    height: 350px;
}
.kasko-card-body p{
    font-size: min(10vw,16px)!important;
}
.kasko-form-container{
  display: flex;
    justify-content: center;
}
.kasko-form-footer{
    display: flex!important;
    justify-content: center!important;
}
.kasko-form-footer button{
    margin:11px 0;
}
.confirmation-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.confirmation-title-container{
    max-height: 50vh;
}
.confirmation-title-container h3{
    font-size: min(12vw,36px);
}
.confirmation-container a{
    margin:10px 0px!important;
    position: unset;
    z-index: 999;
}
.intro-container{
    margin:unset!important;
    padding:unset!important;
    width: 100%;
}

.footer-contact-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.footer-contact-container .contact-card{
    width: min(50%,370px);
    margin:10px;
}
@media screen and (max-width: 768px) {
    .footer-contact-container .contact-card{
        width: 70%;
    }
}
@media screen and (max-width: 500px) {
    .footer-contact-container .contact-card{
        width: 100%;
    }
}
@media screen and (max-width: 425px) {
    .footer-container{
        padding:unset!important;
    }
    .propaganda-container div {
        width: 45%;
        margin: 5px;
    }
}
@media screen and (max-width: 1250px) {
    .kasko-card-body{
        height: 400px;
    }
}
