/* KaskoRequestsView.css */
.kasko-div-block {
    margin-bottom: 20px;
}

.kasko-lynx-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.kasko-lynx-features-bg {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1 1 calc(33.333% - 40px);
    box-sizing: border-box;
}

.kasko-lynx-block-features {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.kasko-button {
    background-color: #417694;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.kasko-button:hover {
    background-color: #417694;
}

.kasko-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.kasko-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.kasko-modal-content p {
    margin: 10px 0;
}

.kasko-modal-content input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.kasko-modal-content button {
    background-color: #417694;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin: 10px 5px 0 0;
    transition: background-color 0.3s;
}

.kasko-modal-content button:hover {
    background-color: #417694;
}

.kasko-modal-content button:nth-child(2) {
    background-color: #f44336;
}

.kasko-modal-content button:nth-child(2):hover {
    background-color: #e53935;
}
