/* kaskorequestsview.css */
.lynx-features-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust spacing between date groups */
}

.lynx-date-group {
    margin-bottom: 20px; /* Adjust spacing between date groups */
}

.lynx-cards-background {
    background-color: #f0f0f0; /* Adjust background color as needed */
    padding: 20px; /* Adjust padding as needed */
    border-radius: 8px; /* Adjust border radius as needed */
}

.lynx-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust card width as needed */
    gap: 20px; /* Adjust spacing between cards */
}

.lynx-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.lynx-card-full-width {
    grid-column: span 2; /* Make the card take up the full width if there's only one card in the row */
}

.lynx-card-content {
    flex: 1;
}

.modal-overlay {
    /* Your existing modal overlay styles */
}

.modal-content {
    /* Your existing modal content styles */
}
