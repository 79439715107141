.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.dropdown-container {
    margin-bottom: 20px;
    text-align: center;
}

.dropdown-menu {
    width: 80%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.response-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.response-details {
    margin: 20px 0;
}

.response-details p {
    margin: 10px 0;
    font-size: 16px;
}

.no-response-message {
    color: red;
    font-weight: bold;
}

.offers-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.offer-box {
    flex: 1;
    min-width: 250px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.offer-box h3 {
    margin-top: 0;
}

.buy-button, .payment-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.buy-button:hover, .payment-button:hover {
    background-color: #0056b3;
}

.pdf-container {
    text-align: center;
    margin: 20px 0;
}

iframe {
    display: block;
    margin: 0 auto;
}

.payment-button {
    display: block;
    margin: 20px auto;
}