.main-page {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    background-color: #f0f0f0;
}

.main-header {
    text-align: center;
}

.main-header h1 {
    font-size: 24px;
    color: #333;
}

.main-menu {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.menu-button {
    background-color: #ffffff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin: 0 10px;
}

.menu-button.active {
    background-color: #ffffff;
}

.content-section {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 20px;
}

.content-section h2 {
    font-size: 20px;
    color: #333;
}