.uui-page-padding {
    padding-left: 2rem;
    padding-right: 2rem;
}
.uui-container-large {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
}
.uui-padding-vertical-xhuge {
    padding-top: 7rem;
    padding-bottom: 7rem;
}
.uui-heroheader23_content {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr .5fr;
    grid-auto-columns: 1fr;
    align-items: end;
    display: grid;
}
.uui-heading-xlarge {
    color: var(--untitled-ui--gray900);
    text-align: left;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    padding-right: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 3.75rem;
    font-weight: 600;
    line-height: 1.2;
}
.uui-max-width-large {
    width: 100%;
    max-width: 48rem;
}
.uui-text-size-xlarge {
    color: var(--untitled-ui--gray600);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.25rem;
    line-height: 1.5;
}

.uui-signup-form_wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr max-content;
    grid-auto-columns: 1fr;
    margin-bottom: .375rem;
    display: grid;
}

.uui-text-size-small {
    color: var(--untitled-ui--gray600);
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    line-height: 1.5;
}

.uui-text-style-link {
    color: var(--untitled-ui--gray600);
    text-decoration: underline;
    transition: color .3s;
}

.uui-text-style-link:hover {
    color: var(--untitled-ui--gray700);
}


.uui-heroheader23_item-list {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: grid;
}

.uui-text-size-large {
    color: var(--untitled-ui--gray600);
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
}

.uui-heroheader23_item-icon-wrapper {
    flex: none;
    align-self: flex-start;
    margin-right: .75rem;
}

.uui-heroheader23_item {
    align-items: center;
    display: flex;
}

.uui-button-link:hover {
    color: var(--untitled-ui--primary800);
}

.section-us {
    height: 350px;
}

.uui-button-icon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    display: flex;
}

.uui-icon-featured-outline-large {
    border: .5rem solid var(--untitled-ui--primary50);
    background-color: var(--untitled-ui--primary100);
    color: var(--untitled-ui--primary600);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
}

.uui-button-row {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.uui-section_layout34 {
    margin-top: 0;
}

.heading-2-us {
    font-family: PT Serif, serif;
}

.text-block-2 {
    font-family: Montserrat, sans-serif;
}

.uui-icon-featured-outline-large {
    border: .5rem solid var(--untitled-ui--primary50);
    background-color: var(--untitled-ui--primary100);
    color: var(--untitled-ui--primary600);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
}

.uui-space-small {
    width: 100%;
    min-height: 1.5rem;
}

.uui-container-large {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-padding-vertical-xhuge {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-space-xxsmall {
    width: 100%;
    min-height: .5rem;
}

.uui-space-small {
    width: 100%;
    min-height: 1.5rem;
}

.uui-text-size-medium {
    color: var(--untitled-ui--gray600);
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.uui-heading-xsmall {
    color: var(--untitled-ui--gray900);
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4;
}

.uui-heroheader23_image-wrapper {
    background-color: var(--untitled-ui--gray500);
    background-image: url('../images/iStock-2077603968_1.webp'), url('../images/iStock-2077603968_1.webp');
    background-position: 50%, 50%;
    background-size: cover, auto;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32.25rem;
    margin-top: 4rem;
    margin-bottom: 68px;
    display: flex;
    position: relative;
    overflow: hidden;
}

.uui-page-padding {
    padding-left: 2rem;
    padding-right: 2rem;
}


@media screen and (max-width: 479px) {
    .uui-heroheader23_content {
        grid-row-gap: 2rem;
        grid-template-columns: 1fr;
    }

    .uui-section_layout34 {
        margin-top: 348px;
    }

    .uui-page-padding {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .uui-signup-form_wrapper {
        grid-row-gap: .75rem;
        flex-direction: column;
        grid-template-columns: 1fr;
    }
    .uui-heroheader23_image-wrapper {
        width: 100vw;
    }
}
@media screen and (max-width: 991px) {
    .uui-heading-xlarge {
        font-size: 3.25rem;
    }
    .uui-text-size-large, .uui-button {
        font-size: 1rem;
    }
    .uui-heroheader23_image-wrapper {
        border-radius: 0;
        width: 100vw;
        margin-left: -2rem;
    }
    .uui-padding-vertical-xhuge {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

}
@media screen and (max-width: 767px) {
    .uui-heading-xlarge {
        font-size: 2.25rem;
    }
    .uui-text-size-xlarge {
        font-size: 1.125rem;
    }
    .uui-text-size-large, .uui-button {
        font-size: 1rem;
    }
    .uui-heroheader23_image-wrapper {
        height: 17rem;
        margin-left: -1rem;
    }
    .uui-section_layout34 {
        margin-top: 301px;
    }
    .uui-page-padding {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-padding-vertical-xhuge {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-space-small {
        min-height: 1.25rem;
    }

    .uui-heading-xsmall {
        font-size: 1.25rem;
    }
    .uui-space-small {
        min-height: 1.25rem;
    }
    .uui-button-row {
        align-self: stretch;
    }
}